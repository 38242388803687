import { memo } from 'react'

import { Box, Grid2, Paper } from '@mui/material'

import { Droppable } from 'react-beautiful-dnd'
import { KeyedMutator } from 'swr'

import ErrorTerm from 'components/term/ErrorTerm'
import Term from 'components/term/Term'

import { IClipboard } from 'contexts/ClipboardContext'

import { termGridItemStyle } from 'shared/components/termStyle'

import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

export type TermDroppableProps = {
  station: IStationData
  tool: IToolData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

/* Carte dans un tool */
function TermDroppable({ station, tool, mutateStudy, clipboard, viewType }: TermDroppableProps) {
  const termDroppableKey = `station-${tool.station}-tool-${tool.id}-term-droppable${
    viewType === 'pin' ? '-pin' : ''
  }`

  return (
    <Grid2 container wrap="nowrap" sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
      <Droppable
        droppableId={termDroppableKey}
        direction="vertical"
        type="term"
        ignoreContainerClipping
      >
        {(provided) => (
          <Grid2 size={12} ref={provided.innerRef} sx={termGridItemStyle()}>
            <Paper className="MuiPaper-term" sx={{ flex: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {tool.terms.map((term, index) =>
                  term.is_error ? (
                    <ErrorTerm key={term.id} term={term} mutateStudy={mutateStudy} />
                  ) : (
                    <Term
                      key={term.id}
                      station={station}
                      tool={tool}
                      term={term}
                      termIndex={index}
                      clipboard={clipboard}
                      viewType={viewType}
                    />
                  ),
                )}
              </Box>

              {provided.placeholder}
            </Paper>
          </Grid2>
        )}
      </Droppable>
    </Grid2>
  )
}

export default memo(TermDroppable)
