import { useEffect, useState } from 'react'

import { Grid2 } from '@mui/material'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'

import { SortableFolderCard } from 'components/project/SortableFolderCard'

import StudyListDataService from 'data-services/StudyListDataService'

import IStudyListData from 'types/IStudyListData'

interface DragAndDropFoldersProps {
  studyLists: IStudyListData[]
  parent: string
}

function DragAndDropFolders({ studyLists, parent }: DragAndDropFoldersProps) {
  const [items, setItems] = useState(studyLists)

  useEffect(() => {
    setItems(studyLists)
  }, [studyLists])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const moveItems = (activeId: UniqueIdentifier, overId: UniqueIdentifier) => {
    const oldPosition = items.find(({ id }) => id === activeId).order
    const newPosition = items.find(({ id }) => id === overId).order

    StudyListDataService.moveStudyList(String(activeId), {
      destination_folder: parent,
      destination_index: newPosition,
      destination_team: undefined,
    })

    return arrayMove(items, oldPosition, newPosition)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const movedItems = moveItems(active.id, over.id)

      const newOrderStudies = movedItems.map((study, index) => ({
        ...study,
        order: index,
      }))

      setItems(newOrderStudies)
    }
  }

  return (
    <Grid2 container spacing={2}>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={rectSortingStrategy}>
          {items.map((studyList) => (
            <Grid2 size={{ xs: 12, sm: 6 }} key={`studyList-${studyList.id}`}>
              <SortableFolderCard studyList={studyList} />
            </Grid2>
          ))}
        </SortableContext>
      </DndContext>
    </Grid2>
  )
}

export { DragAndDropFolders }
