import { MouseEvent, Suspense, lazy, memo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import {
  Box,
  Typography,
  Container,
  IconButton,
  Tooltip,
  Chip,
  CircularProgress,
  Button,
  MenuItem,
  Menu,
} from '@mui/material'
import { AddOutlined, ArrowBack, ArrowDropDown } from '@mui/icons-material'

import { DragAndDropFolders } from 'components/project/DragAndDropFolders'
import { DragAndDropStudies } from 'components/project/DragAndDropStudies'
import { EmptyProject } from 'components/project/EmptyProject'
import { ProgressBackdrop } from 'components/control/ProgressBackdrop'
import { ProjectMoreActionsMenu } from 'components/project/ProjectMoreActionsMenu'
import ProjectMenu from 'components/SideMenuDrawer'

import { useStudyList } from 'hooks/useStudyList'
import { useRootStudyLists } from 'hooks/useRootStudyLists'

import paths from 'utils/paths'

import { colors } from 'shared/theme'
import sideMenuDrawerRightBoxStyle from 'shared/components/sideMenuRightBoxStyle'

import IStudyData from 'types/IStudyData'

const ProjectDialog2 = lazy(() => import('components/dialog/ProjectDialog2'))
const StudyDialog = lazy(() => import('components/dialog/StudyDialog'))

function Project() {
  const { t } = useLingui()

  const { id } = useParams()
  const navigate = useNavigate()

  const [studyDialogOpen, setStudyDialogOpen] = useState(false)
  const [folderDialogOpen, setFolderDialogOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [selectedStudy, setSelectedStudy] = useState<IStudyData | null>(null)

  const [createMenuAnchorEl, setCreateMenuAnchorEl] = useState<HTMLElement | null>(null)
  const createMenuOpen = !!createMenuAnchorEl

  const [projectMenuAnchorEl, setProjectMenuAnchorEl] = useState<HTMLElement | null>(null)
  const projectMenuOpen = !!projectMenuAnchorEl

  const { rootStudyLists } = useRootStudyLists()
  const { studyList, mutateStudyList, isLoading, isValidating } = useStudyList(id)

  const handleProjectMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setProjectMenuAnchorEl(event.currentTarget)
  }

  const handleCreateMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setCreateMenuAnchorEl(event.currentTarget)
  }

  const handleCreateMenuClose = () => {
    setCreateMenuAnchorEl(null)
  }

  const handleCreateStudy = () => {
    setSelectedStudy(null)
    setStudyDialogOpen(true)
    handleCreateMenuClose()
  }

  const handleCreateFolder = () => {
    setFolderDialogOpen(true)
    handleCreateMenuClose()
  }

  const handleBack = () => {
    if (studyList?.data?.parent) {
      navigate(paths.frontend.studyList(studyList.data.parent))
      return
    }

    navigate('/')
  }

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: { xs: '0 24px', md: '0 24px 0 0' } }}
      >
        <Box display="flex">
          {/* LEFT SECTION */}
          <ProjectMenu studyLists={rootStudyLists?.data} />

          {/* RIGHT SECTION */}
          <Box sx={sideMenuDrawerRightBoxStyle()}>
            <Box display="flex" flexDirection="column">
              {/* TOP BAR */}
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems="flex-start"
                gap={{ xs: '24px', md: '0' }}
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                <Box
                  display="flex"
                  alignItems="start"
                  sx={{ width: '100%', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  <Tooltip title={t`Back`} disableInteractive>
                    <IconButton color="inherit" size="small" onClick={handleBack}>
                      <ArrowBack />
                    </IconButton>
                  </Tooltip>

                  <Box sx={{ textOverflow: 'ellipsis', overflow: 'hidden', ml: '4px' }}>
                    <Box display="flex" alignItems="center">
                      <Typography variant="h1" fontWeight={400} sx={{ color: colors.blue.A800 }}>
                        {studyList?.data?.title && studyList.data.title !== ''
                          ? studyList.data.title
                          : t`New project`}
                      </Typography>

                      <IconButton
                        id="project-menu-button"
                        color="inherit"
                        size="small"
                        onClick={handleProjectMenuClick}
                      >
                        <ArrowDropDown />
                      </IconButton>
                    </Box>

                    <Box>
                      <Box>
                        <Typography>{studyList?.data?.note}</Typography>
                      </Box>

                      {studyList?.data?.team.id && (
                        <Chip
                          onClick={() => navigate(paths.frontend.teams(studyList?.data?.team.id))}
                          label={
                            <Typography color="text" fontWeight={450}>
                              {studyList?.data?.team.name}
                            </Typography>
                          }
                        />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    id="create-button"
                    onClick={handleCreateMenuClick}
                    variant="containedDarkBlue"
                    sx={{ width: 'auto', padding: '8px 24px' }}
                    startIcon={<AddOutlined fontSize="large" />}
                    disabled={!studyList?.data}
                  >
                    <Typography fontWeight={500} noWrap>
                      {t`Create new...`}
                    </Typography>
                  </Button>
                </Box>
              </Box>

              {/* BODY */}

              {isLoading || isValidating ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: { xs: '30% 0 0 0', md: '15% 0 0 0' },
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {studyList && studyList.data?.children.length > 0 && (
                    <Box sx={{ mt: '24px' }}>
                      <Typography variant="h3" sx={{ mb: '24px' }}>{t`Folders`}</Typography>
                      <DragAndDropFolders studyLists={studyList.data.children} parent={id} />
                    </Box>
                  )}

                  {!submitting && studyList && studyList.data?.studies.length > 0 && (
                    <Box sx={{ my: '24px' }}>
                      <DragAndDropStudies
                        mutateStudyList={mutateStudyList}
                        setSelectedStudy={setSelectedStudy}
                        setStudyDialogOpen={setStudyDialogOpen}
                        studies={studyList.data.studies}
                        studyListId={id}
                      />
                    </Box>
                  )}

                  {submitting && <ProgressBackdrop open={submitting} margin="0 0 0 15% " />}

                  {studyList && studyList.data?.studies.length === 0 && <EmptyProject />}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Container>

      <ProjectMoreActionsMenu
        studyList={studyList?.data}
        anchorMenuOpen={projectMenuOpen}
        setProjectMenuAnchorEl={setProjectMenuAnchorEl}
        projectMenuAnchorEl={projectMenuAnchorEl}
      />

      {createMenuOpen && (
        <Menu
          id="create-menu"
          MenuListProps={{
            'aria-labelledby': 'create-button',
          }}
          anchorEl={createMenuAnchorEl}
          open={createMenuOpen}
          onClose={handleCreateMenuClose}
        >
          <MenuItem onClick={handleCreateStudy}>{t`Create Board`}</MenuItem>
          <MenuItem onClick={handleCreateFolder}>{t`Create Folder`}</MenuItem>
        </Menu>
      )}

      {folderDialogOpen && (
        <Suspense fallback={null}>
          <ProjectDialog2
            open={folderDialogOpen}
            parentProject={studyList.data}
            onModalClose={() => setFolderDialogOpen(false)}
          />
        </Suspense>
      )}

      {studyDialogOpen && (
        <Suspense fallback={null}>
          <StudyDialog
            projectId={studyList?.data.id}
            study={selectedStudy}
            open={studyDialogOpen}
            onModalClose={() => setStudyDialogOpen(false)}
            mutator={mutateStudyList}
            setSubmitting={setSubmitting}
          />
        </Suspense>
      )}
    </>
  )
}

export default memo(Project)
