import { memo, useEffect, useRef } from 'react'

import { Box } from '@mui/material'

import { KeyedMutator } from 'swr'

import TermDroppable from 'components/term/TermDroppable'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard } from 'contexts/ClipboardContext'

import toolStyle from 'shared/components/toolStyle'

import IStationData from 'types/IStationData'
import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

import { AIDialogControl } from './AIDialogControl'
import NewTerm from './NewTerm'
import ToolHeader from './ToolHeader'

type ToolProps = {
  study: IStudyData
  tool: IToolData
  station: IStationData
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
  viewType: 'grid' | 'pin'
}

/*
 * Carré blanc avec soit AI ou pas
 */
function Tool({ study, tool, station, mutateStudy, clipboard, viewType }: ToolProps): JSX.Element {
  const isInitialToolRender = useRef(true)

  const { actionMenuContext, setActionMenuContext } = useActionMenuContext()

  useEffect(() => {
    if (isInitialToolRender.current) {
      isInitialToolRender.current = false
    } else if (clipboard.tools.getItemState(tool.id) !== null) {
      clipboard.tools.updateItemData(tool)
    }
  }, [tool])

  const isToolActive = (): boolean =>
    !!actionMenuContext && actionMenuContext.tool?.id === tool.id && !actionMenuContext.term

  const handleToolClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setActionMenuContext({
      stationGridItem: `station-grid-item-${station.row}-${station.column}`,
      station,
      toolGridItem: `${station.id}-tool-droppable-${tool.row}-${tool.column}`,
      tool,
      stationListId: null,
      term: null,
    })
  }

  return (
    <Box sx={toolStyle(isToolActive())} onClick={handleToolClick}>
      <ToolHeader tool={tool} station={station} clipboard={clipboard} />

      {tool.tool_type === 'ai' && (
        <Box sx={{ mt: 1 }}>
          <AIDialogControl study={study} tool={tool} />
        </Box>
      )}

      <Box sx={{ mt: 1 }}>
        <NewTerm tool={tool} station={station} mutateStudy={mutateStudy} />
      </Box>

      <TermDroppable
        station={station}
        tool={tool}
        mutateStudy={mutateStudy}
        clipboard={clipboard}
        viewType={viewType}
      />
    </Box>
  )
}

export default memo(Tool)
