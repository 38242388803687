import { lazy, memo, Suspense, useEffect, useReducer, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import { Box, Typography, Container, IconButton, Tooltip, Button } from '@mui/material'
import {
  ArrowBack as ArrowBackIcon,
  SettingsOutlined as SettingsOutlinedIcon,
} from '@mui/icons-material'

import ProjectMenu from 'components/SideMenuDrawer'
import TeamForm from 'components/form/TeamForm'

import { useRootStudyLists } from 'hooks/useRootStudyLists'
import { useTeam } from 'hooks/useTeams'

import paths from 'utils/paths'

import sideMenuDrawerRightBoxStyle from 'shared/components/sideMenuRightBoxStyle'
import { colors } from 'shared/theme'
import { DragAndDropFolders } from 'components/project/DragAndDropFolders'
import { Loading } from 'components/Loading'

const ProjectDialog2 = lazy(() => import('components/dialog/ProjectDialog2'))

const initialState = {
  showTeamView: false,
  showTeamAdd: false,
  showTeamSetting: false,
}

type Action = { type: 'TEAM_VIEW' } | { type: 'TEAM_ADD' } | { type: 'TEAM_SETTING' }

function reducer(_: typeof initialState, action: Action) {
  switch (action.type) {
    case 'TEAM_VIEW':
      return {
        ...initialState,
        showTeamView: true,
      }

    case 'TEAM_ADD':
      return {
        ...initialState,
        showTeamAdd: true,
      }

    case 'TEAM_SETTING':
      return {
        ...initialState,
        showTeamSetting: true,
      }

    default:
      return initialState
  }
}

function Team() {
  const { t } = useLingui()

  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { rootStudyLists } = useRootStudyLists()
  const { team, isLoading } = useTeam(id)

  const [projectDialogOpen, setProjectDialogOpen] = useState(false)
  const [viewState, dispatchViewState] = useReducer(reducer, initialState)

  useEffect(() => {
    if (location) {
      const { pathname } = location
      if (pathname.includes('/setting/') && id) dispatchViewState({ type: 'TEAM_SETTING' })
      else if (pathname.includes('/add/')) dispatchViewState({ type: 'TEAM_ADD' })
      else if (id) {
        dispatchViewState({ type: 'TEAM_VIEW' })
      }
    }
  }, [location, id])

  const getPageTitle = (): string => {
    if (viewState.showTeamView && team) return team.data.name

    if (viewState.showTeamSetting && team) return t`Team settings`

    return t`Create new team`
  }

  const getBackPath = (): string => {
    if (viewState.showTeamSetting && team) return paths.frontend.teams(team.data.id)

    return '-1'
  }

  const getTeamContent = () => {
    if (isLoading) {
      return <Loading sx={{ height: '272px' }} />
    }

    return (
      <Box my={3}>
        <DragAndDropFolders studyLists={team?.data.study_lists} parent={null} />
      </Box>
    )
  }

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: { xs: '0 24px 0 24px', md: '0 24px 0 0' } }}
      >
        <Box display="flex">
          {/* LEFT SECTION */}
          <ProjectMenu studyLists={rootStudyLists?.data} />

          {/* RIGHT SECTION */}
          <Box sx={sideMenuDrawerRightBoxStyle()}>
            <Box display="flex" flexDirection="column">
              {/* TOP BAR */}
              <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                alignItems="flex-start"
                justifyContent="space-between"
                gap={{ xs: '24px', md: '0' }}
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
              >
                <div>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '40px',
                        marginTop: '4px',
                      }}
                    >
                      <Tooltip title={t`Back`} disableInteractive>
                        <IconButton color="inherit" onClick={() => navigate(getBackPath())}>
                          <ArrowBackIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <Box sx={{ display: 'flex', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      <Typography variant="h1" fontWeight={400} sx={{ color: colors.blue.A800 }}>
                        {getPageTitle()}
                      </Typography>
                    </Box>
                  </Box>

                  {viewState.showTeamView && (
                    <Box display="flex" margin="16px 0 0 0" gap="24px">
                      <Typography variant="h5" fontWeight={500} color="textSecondary">
                        {t`Members`}
                      </Typography>

                      {team?.data?.users.map((user) => (
                        <Typography variant="h5" color="textSecondary" key={user.name}>
                          {user.name}
                        </Typography>
                      ))}
                    </Box>
                  )}
                </div>

                {viewState.showTeamView && (
                  <Box>
                    <Button
                      onClick={() => navigate(paths.frontend.teamsSetting(team?.data?.id))}
                      variant="containedLightBlue"
                      type="button"
                      sx={{ width: 'auto', padding: '8px 24px' }}
                      startIcon={<SettingsOutlinedIcon />}
                    >
                      <Typography fontWeight={500} noWrap>
                        {t`Team settings`}
                      </Typography>
                    </Button>
                  </Box>
                )}
              </Box>

              {/* BODY */}
              <Box margin="8px 0 0 0">
                {viewState.showTeamView && getTeamContent()}

                {viewState.showTeamAdd && (
                  <Box margin="32px 0 0 0" sx={{ width: { xs: '100%', md: '50%' } }}>
                    <TeamForm />
                  </Box>
                )}

                {viewState.showTeamSetting && team && (
                  <Box margin="32px 0 0 0" sx={{ width: { xs: '100%', md: '50%' } }}>
                    <TeamForm team={team ? team?.data : null} />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      {projectDialogOpen && (
        <Suspense fallback={null}>
          <ProjectDialog2
            open={projectDialogOpen}
            currentTeam={team?.data.id}
            onModalClose={() => setProjectDialogOpen(false)}
          />
        </Suspense>
      )}
    </>
  )
}

export default memo(Team)
