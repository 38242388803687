import { Dispatch, lazy, RefObject, SetStateAction, Suspense, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import { KeyedMutator, MutatorCallback } from 'swr'

import { Typography, MenuItem, ListItemIcon, Divider } from '@mui/material'
import {
  DeleteForeverOutlined as DeleteForeverIcon,
  EditOutlined,
  SettingsOutlined as SettingsOutlinedIcon,
  DriveFileMove,
} from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import PopperComponent from 'components/PopperComponent'

import StudyDataService from 'data-services/StudyDataService'

import paths from 'utils/paths'

import IStudyData from 'types/IStudyData'
import { colors } from 'shared/theme'

const ConfirmDialog = lazy(() => import('components/control/ConfirmDialog'))
const StudyConfigDialog = lazy(() => import('components/dialog/StudyConfigDialog'))
const StudyMoveDialog = lazy(() => import('components/dialog/StudyMoveDialog'))

type StudyMoreActionMenuProps = {
  studyListId?: string
  study: IStudyData
  mutator: MutatorCallback
  anchorMenuOpen: boolean
  setAnchorMenuOpen: Dispatch<SetStateAction<boolean>>
  anchorMenuRef: RefObject<HTMLButtonElement>
  setSelectedStudy?: Dispatch<SetStateAction<IStudyData | null>>
  setStudyDialogOpen: Dispatch<SetStateAction<boolean>>
}

export type DeleteDialogType = {
  open: boolean
  deleteType?: 'study' | 'station'
}

export default function StudyMoreActionMenu(props: StudyMoreActionMenuProps) {
  const {
    studyListId,
    study,
    mutator,
    anchorMenuOpen,
    setAnchorMenuOpen,
    anchorMenuRef,
    setSelectedStudy,
    setStudyDialogOpen,
  } = props
  const { t } = useLingui()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<DeleteDialogType>({ open: false })
  const [configDialogOpen, setConfigDialogOpen] = useState(false)
  const [moveDialogOpen, setMoveDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleEditStudyClick = () => {
    if (setSelectedStudy) setSelectedStudy(study)

    setStudyDialogOpen(true)
    setAnchorMenuOpen(false)
  }

  const handleOpenConfigDialog = () => {
    setConfigDialogOpen(true)
  }

  const handleCloseConfigDialog = () => {
    setConfigDialogOpen(false)
  }

  const handleDeleteStudyClick = () => {
    setDeleteDialogOpen({ open: true, deleteType: 'study' })
    setAnchorMenuOpen(false)
  }

  const handleDeleteStudyConfirmClick = () => {
    const studyClone = study
    if (!studyListId) {
      navigate(paths.frontend.projects)
    }

    StudyDataService.deleteStudy(studyClone.id)
      .then(() => {
        mutator().then(() => {
          enqueueSnackbar(
            `Study "${
              studyClone.title && studyClone.title !== '' ? studyClone.title : t`New study`
            }" successfully deleted`,
            { variant: 'success' },
          )

          setDeleteDialogOpen({ open: false })
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
        setDeleteDialogOpen({ open: false })
      })
  }

  const handleDeleteStudyStationsConfirmClick = () => {
    StudyDataService.deleteStudyStations(study.id)
      .then(({ data: studyResponse }) => {
        const mutateStudy = mutator as unknown as KeyedMutator<IStudyData>
        mutateStudy(studyResponse, false).then(() => {
          enqueueSnackbar(t`Station(s) successfully deleted`, { variant: 'success' })
          setDeleteDialogOpen({ open: false })
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
        setDeleteDialogOpen({ open: false })
      })
  }

  return (
    <>
      <PopperComponent
        anchorMenu={anchorMenuOpen}
        setAnchorMenu={setAnchorMenuOpen}
        anchorRef={anchorMenuRef}
        backgroundColor={colors.lightBlue.A400}
        sx={{ minWidth: '185px', zIndex: 1 }}
      >
        <MenuItem onClick={handleEditStudyClick} sx={{ padding: '8px' }}>
          <ListItemIcon>
            <EditOutlined sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Edit study`}</Typography>
        </MenuItem>

        <MenuItem onClick={handleOpenConfigDialog} sx={{ padding: '8px' }}>
          <ListItemIcon>
            <SettingsOutlinedIcon sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Configure`}</Typography>
        </MenuItem>

        <MenuItem onClick={() => setMoveDialogOpen(true)} sx={{ padding: '8px' }}>
          <ListItemIcon>
            <DriveFileMove sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Move`}</Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={handleDeleteStudyClick}
          className="MuiMenu-error"
          sx={{ padding: '8px' }}
        >
          <ListItemIcon>
            <DeleteForeverIcon color="error" />
          </ListItemIcon>
          <Typography color="error">{t`Delete Study`}</Typography>
        </MenuItem>
      </PopperComponent>

      {deleteDialogOpen.open && (
        <Suspense fallback={null}>
          <ConfirmDialog
            confirmColor="secondary"
            confirmText={
              deleteDialogOpen.deleteType === 'study' ? t`Delete study` : t`Delete stations`
            }
            description={
              deleteDialogOpen.deleteType === 'study'
                ? t`Are you sure you want to delete this study?`
                : t`Are you sure you want to delete the station(s)?`
            }
            open={deleteDialogOpen.open}
            onClose={() => setDeleteDialogOpen({ open: false, deleteType: null })}
            onConfirm={
              deleteDialogOpen.deleteType === 'study'
                ? handleDeleteStudyConfirmClick
                : handleDeleteStudyStationsConfirmClick
            }
            title={deleteDialogOpen.deleteType === 'study' ? t`Study` : t`Study station(s)`}
            confirmButtonVariant="containedRed"
            cancelButtonVariant="containedWhite"
          />
        </Suspense>
      )}

      {configDialogOpen && (
        <Suspense fallback={null}>
          <StudyConfigDialog
            open={configDialogOpen}
            onClose={handleCloseConfigDialog}
            study={study}
            mutator={mutator}
          />
        </Suspense>
      )}

      {moveDialogOpen && (
        <Suspense fallback={null}>
          <StudyMoveDialog
            study={study}
            open={moveDialogOpen}
            onModalClose={() => setMoveDialogOpen(false)}
          />
        </Suspense>
      )}
    </>
  )
}
