import { Outlet } from 'react-router'
import { Avatar, Box, Button, CssBaseline, Container } from '@mui/material'
import { dynamicActivate, locales, setInitialLanguage } from '../../utils/i18n'

export default function AuthContainer(): JSX.Element {
  const handleChangeLanguage = (newLanguage: string): void => {
    setInitialLanguage(newLanguage)
    dynamicActivate(newLanguage)
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Avatar sx={{ backgroundColor: 'secondary.main', m: 1 }} />
        <Outlet />
        <Box sx={{ mt: 6 }}>
          {Object.values(locales).map((locale, index) => (
            <Button
              onClick={() => handleChangeLanguage(Object.keys(locales)[index])}
              variant="text"
              key={locale}
            >
              {locale}
            </Button>
          ))}
        </Box>
      </Box>
    </Container>
  )
}
