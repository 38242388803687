import { Backdrop, CircularProgress } from '@mui/material'

export type ProgressBackdropProps = {
  open: boolean
  margin?: string
}

function ProgressBackdrop({ open, margin }: ProgressBackdropProps) {
  return (
    <Backdrop open={open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <CircularProgress color="inherit" sx={{ margin }} />
    </Backdrop>
  )
}

export { ProgressBackdrop }
