import { SyntheticEvent, useMemo, useState } from 'react'
import { Link } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import { Autocomplete, Box, CircularProgress, styled, TextField } from '@mui/material'
import { Folder, PeopleAlt, TableChart } from '@mui/icons-material'

import { useStudyLists } from 'hooks/useStudyLists'
import { useTeams } from 'hooks/useTeams'

import paths from 'utils/paths'

import { colors } from 'shared/theme'

enum SearchableType {
  Folder = 'Folders',
  Board = 'Boards',
  Team = 'Teams',
}

interface Searchable {
  id: string
  title: string
  type: SearchableType
}

interface SearchProps {
  onSearch?: () => void
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '10px',
  color: colors.black.A500,
  backgroundColor: 'white',
  fontWeight: 500,
}))

const GroupItems = styled('ul')({
  padding: 0,
})

function Search({ onSearch = () => {} }: SearchProps) {
  const { t } = useLingui()
  const [inputValue, setInputValue] = useState('')

  const { studyLists } = useStudyLists()
  const { teams } = useTeams()

  const options: Searchable[] = useMemo(() => {
    if (teams && studyLists) {
      const searchableTeams = teams.data.map((team) => ({
        id: team.id,
        title: team.name,
        type: SearchableType.Team,
      }))

      // Get all Folders and Boards and sort them with Folders showing up first in the array
      const searchableItems = studyLists.data
        .flatMap(({ id, title, studies }) => [
          {
            id,
            title,
            type: SearchableType.Folder,
          },
          ...studies.map(({ id, title }) => ({
            id,
            title,
            type: SearchableType.Board,
          })),
        ])
        .sort((a, b) => {
          if (a.type === SearchableType.Folder && b.type !== SearchableType.Folder) {
            return -1
          }
          if (a.type !== SearchableType.Folder && b.type === SearchableType.Folder) {
            return 1
          }
          return 0
        })

      return [...searchableTeams, ...searchableItems]
    }
    return []
  }, [studyLists, teams])

  const getGroupTitle = (type: SearchableType) => {
    switch (type) {
      case SearchableType.Board:
        return t`Boards`
      case SearchableType.Folder:
        return t`Folders`
      case SearchableType.Team:
        return t`Teams`
      default:
        return null
    }
  }

  const getIcon = (type: SearchableType) => {
    switch (type) {
      case SearchableType.Board:
        return <TableChart sx={{ color: colors.blue.A500 }} />
      case SearchableType.Folder:
        return <Folder sx={{ color: colors.manilla.A500 }} />
      case SearchableType.Team:
        return <PeopleAlt sx={{ color: colors.blue.A400 }} />
      default:
        return null
    }
  }

  const getRoute = (id: string, type: SearchableType) => {
    switch (type) {
      case SearchableType.Board:
        return paths.frontend.study(id)
      case SearchableType.Folder:
        return paths.frontend.studyList(id)
      case SearchableType.Team:
        return paths.frontend.teams(id)
      default:
        return null
    }
  }

  const handleSearchChange = () => {
    onSearch()
    setInputValue('')
  }

  const handleInputChange = (_: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue)
  }

  return (
    <Autocomplete
      sx={{ width: { xs: 200, sm: 300 } }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={({ title }) => title}
      options={options}
      loading={options.length === 0}
      value={null}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleSearchChange}
      groupBy={(option) => option.type}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{getGroupTitle(params.group as SearchableType)}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, { id, title, type }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { key, ...optionProps } = props

        return (
          <Link key={id} to={getRoute(id, type)} style={{ textDecoration: 'none' }}>
            <Box
              component="li"
              sx={{
                '& > svg': { mr: 2, flexShrink: 0 },
                alignItems: 'start',
                color: colors.black.A500,
              }}
              {...optionProps}
            >
              {getIcon(type)}
              {title}
            </Box>
          </Link>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t`Search`}
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <>
                  {options.length === 0 ? (
                    <CircularProgress color="inherit" size={20} sx={{ mr: 3 }} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
    />
  )
}

export { Search }
