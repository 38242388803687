import { useLingui } from '@lingui/react/macro'

import { Box, Typography } from '@mui/material'

import folderImage from 'assets/folder.svg'

function EmptyProject() {
  const { t } = useLingui()

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      flexGrow={1}
      margin={{ xs: '30% 0 0 0', md: '15% 0 0 0' }}
      flexDirection="column"
    >
      <Box
        component="img"
        sx={{
          height: 'auto',
          width: 'auto',
        }}
        src={folderImage}
      />

      <Box display="flex" flexDirection="column" gap="32px" margin="48px 0 0 0">
        <Typography textAlign="center">{t`This project doesn’t have any studies.`}</Typography>
        <Typography textAlign="center">{t`Create a new study or import from your files or from a template.`}</Typography>
      </Box>
    </Box>
  )
}

export { EmptyProject }
