import { useParams } from 'react-router'

import { useLingui } from '@lingui/react/macro'
import { Box, Container } from '@mui/material'

import { Loading } from 'components/Loading'
import StudyContent from 'components/study/StudyContent'

import { HeaderHeightContextProvider } from 'contexts/HeaderHeight'

import { useStudy } from 'hooks/useStudy'

import theme from 'shared/theme'

function Study() {
  const { t } = useLingui()
  const { id } = useParams()

  const { data: study, error, isLoading, mutate } = useStudy(id)

  const containerSx = { margin: `${theme.appBarHeight} 0 0 0`, height: 'calc(100vh  - 64px)' }
  const boxSx = { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }

  if (isLoading) {
    return (
      <Container disableGutters maxWidth={false} sx={containerSx}>
        <Loading sx={{ height: '100%' }} />
      </Container>
    )
  }

  if (error) {
    return (
      <Container disableGutters maxWidth={false} sx={containerSx}>
        <Box sx={boxSx}>{t`An error occurred`}</Box>
      </Container>
    )
  }

  return (
    <Container disableGutters maxWidth={false} sx={containerSx}>
      <HeaderHeightContextProvider>
        <StudyContent study={study} mutateStudy={mutate} />
      </HeaderHeightContextProvider>
    </Container>
  )
}

export default Study
