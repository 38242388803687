/* eslint-disable camelcase */

import { t } from '@lingui/core/macro'
import yup from 'utils/yupLocale'
import ITermData, { TermExportProps, TermImportSchema } from 'types/ITermData'

export default interface IToolData {
  id: string
  title: string
  subtitle: string
  note?: string
  terms: ITermData[]
  station: string
  source_language: string
  destination_language: string
  language_code: string
  tool_type: string
  is_template: boolean
  is_processing: boolean
  ai_provider: string
  ai_model: string
  ai_personality: string
  ai_personality_title: string
  ai_journal: string
  is_prompt_to_card: boolean
  is_prompt_to_journal: boolean
  ai_temperature: number
  ai_contexts: IToolAIContextData
  ai_context_lists: string[]
  is_ai_personality: boolean
  row: number
  column: number
  prompt: string
}

export const ToolExportProps: string[] = [
  'title',
  'subtitle',
  'note',
  'source_language',
  'destination_language',
  'language_code',
  'ai_provider',
  'ai_model',
  'ai_personality',
  'ai_journal',
  'is_prompt_to_card',
  'is_prompt_to_journal',
  'ai_temperature',
  'ai_context_lists',
  'is_ai_personality',
  'tool_type',
  'is_template',
  'terms',
  ...TermExportProps,
]

interface List {
  list_id: string
  list_title: string
}

export interface Board {
  board_id: string
  board_title: string
  lists: List[]
}

export interface IToolAIContextData {
  [key: string]: Board
}

export interface IToolTypeData {
  id: string
  text: string
}

export interface IToolListData {
  id: string
  title: string
  note: string
}

export const ToolImportSchema = yup
  .object()
  .shape({
    title: yup.string(),
    subtitle: yup.string(),
    note: yup.string(),
    terms: yup.array().of(TermImportSchema),
    tool_type: yup
      .string()
      .matches(/^(agents|antonyms|chatgpt|definitions|examples|related|synonyms|ai)$/, {
        excludeEmptyString: true,
      }),
    is_template: yup.boolean(),
    isTemplate: yup.boolean(),
    language_code: yup.string().nullable(),
    languageCode: yup.string().nullable(),
    ai_provider: yup.string().nullable(),
    ai_model: yup.string().nullable(),
    ai_personality: yup.string().nullable(),
    ai_journal: yup.string().nullable(),
    is_prompt_to_card: yup.boolean(),
    is_prompt_to_journal: yup.boolean(),
    ai_temperature: yup.string().nullable(),
    is_ai_personality: yup.boolean(),
    row: yup.string(),
    column: yup.string(),
  })
  .test(
    'is_template',
    'is_template or isTemplate must be defined',
    (data) => data.is_template !== undefined || data.isTemplate !== undefined,
  )
  .test('tool_type', 'tool_type must be defined', (data) => data.tool_type !== undefined)
export const serializeTool = (tool: IToolData): string => JSON.stringify(tool, ToolExportProps)

export const getToolTypes = (): IToolTypeData[] => [
  // { id: 'blank', text: t`Blank` },
  { id: 'ai', text: t`AI` },
  // { id: 'chatgpt', text: t`ChatGPT` },
  // { id: 'agents', text: t`Agents` },
  // { id: 'antonyms', text: t`Antonyms` },
  // { id: 'definitions', text: t`Definitions` },
  // { id: 'examples', text: t`Examples` },
  // { id: 'related', text: t`Related` },
  // { id: 'synonyms', text: t`Synonyms` },
  // { id: 'translate', text: t`Translate` },
  // { id: "answers", text: t`Answers` },
  // { id: "help", text: t`Help` },
  // { id: "concepts", text: t`Concepts` },
  // { id: "groupings", text: t`Groupings` },
  // { id: "classify", text: t`Classify` },
  // { id: "recommendations", text: t`Recommendations` },
]

export const getTypeToolText = (toolType: string): string => {
  switch (toolType) {
    // case 'answers':
    //   return t`Answers`
    // case 'antonyms':
    //   return t`Antonyms`
    // case 'classify':
    //   return t`Classify`
    // case 'concepts':
    //   return t`Concepts`
    // case 'definitions':
    //   return t`Definitions`
    // case 'examples':
    //   return t`Examples`
    // case 'groupings':
    //   return t`Groupings`
    // case 'help':
    //   return t`Help`
    // case 'recommendations':
    //   return t`Recommendations`
    // case 'related':
    //   return t`Related`
    // case 'synonyms':
    //   return t`Synonyms`
    // case 'chatgpt':
    //   return t`ChatGPT`
    // case 'agents':
    //   return t`Agents`
    // case 'translate':
    //   return t`Translate`
    case 'ai':
      return t`AI Colleague`
    default:
      return toolType
  }
}

export const getLanguageText = (language: string): string => {
  switch (language) {
    case 'arb':
      return t`arb`
    case 'bg':
      return t`bg`
    case 'ca':
      return t`ca`
    case 'cmn-Hans':
      return t`cmn-Hans`
    case 'da':
      return t`da`
    case 'el':
      return t`el`
    case 'es':
      return t`es`
    case 'eu':
      return t`eu`
    case 'fi':
      return t`fi`
    case 'fr':
      return t`fr`
    case 'gl':
      return t`gl`
    case 'he':
      return t`he`
    case 'hr':
      return t`hr`
    case 'id':
      return t`id`
    case 'is':
      return t`is`
    case 'it':
      return t`it`
    case 'ja':
      return t`ja`
    case 'lt':
      return t`lt`
    case 'nb':
      return t`nb`
    case 'nl':
      return t`nl`
    case 'nn':
      return t`nn`
    case 'pl':
      return t`pl`
    case 'pt':
      return t`pt`
    case 'ro':
      return t`ro`
    case 'sk':
      return t`sk`
    case 'sl':
      return t`sl`
    case 'sq':
      return t`sq`
    case 'sv':
      return t`sv`
    case 'th':
      return t`th`
    case 'zsm':
      return t`zsm`
    case 'en':
      return t`en`
    case 'de':
      return t`de`
    case 'ckb':
      return t`ckb`
    default:
      return language
  }
}

export const TOOL_TYPES: string[] = [
  // 'answers',
  'antonyms',
  // 'classify',
  // 'concepts',
  'definitions',
  'examples',
  // 'groupings',
  // 'help',
  'related',
  // 'recommendations',
  'synonyms',
  'chatgpt',
  'agents',
  'ai',
  // 'translate',
]

export const LANGUAGES: string[] = [
  'arb',
  'bg',
  'ca',
  'cmn-Hans',
  'da',
  'el',
  'es',
  'eu',
  'fi',
  'fr',
  'gl',
  'he',
  'hr',
  'id',
  'is',
  'it',
  'ja',
  'lt',
  'nb',
  'nl',
  'nn',
  'pl',
  'pt',
  'ro',
  'sk',
  'sl',
  'sq',
  'sv',
  'th',
  'zsm',
  'en',
  'de',
  'ckb',
]
