import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useLingui } from '@lingui/react/macro'

export type ConfirmDialogProps = {
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  confirmButtonVariant?:
    | 'text'
    | 'outlined'
    | 'contained'
    | 'containedLightBlue'
    | 'containedDarkBlue'
    | 'containedWhite'
    | 'containedRed'
    | undefined
  cancelButtonVariant?:
    | 'text'
    | 'outlined'
    | 'contained'
    | 'containedLightBlue'
    | 'containedDarkBlue'
    | 'containedWhite'
    | 'containedRed'
    | undefined
  confirmText?: string
  description: string
  onClose: () => void
  onConfirm: () => void
  open: boolean
  title: string
}

function ConfirmDialog({
  confirmColor = 'primary',
  confirmButtonVariant,
  cancelButtonVariant,
  confirmText = 'OK',
  description,
  onClose,
  onConfirm,
  open,
  title,
}: ConfirmDialogProps) {
  const { t } = useLingui()

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          padding: '24px',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ padding: 0 }}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h1" fontWeight={400} color="textPrimary">
              {title}
            </Typography>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ margin: '16px 0 0 0', padding: 0 }}>
        <DialogContentText
          variant="h5"
          fontWeight={400}
          color="textPrimary"
          id="alert-dialog-description"
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ margin: '24px 0 0 0', padding: 0 }}>
        <Button
          onClick={onClose}
          variant={cancelButtonVariant ?? 'outlined'}
          size="small"
        >{t`Cancel`}</Button>
        <Button
          autoFocus
          variant={confirmButtonVariant ?? 'contained'}
          color={confirmColor}
          onClick={onConfirm}
          size="small"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ConfirmDialog)
