import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Alert, Box, Button, Grid2, Link } from '@mui/material'
import { useLingui } from '@lingui/react/macro'
import { ProgressBackdrop } from '../../components/control/ProgressBackdrop'
import AccountDataService from '../../data-services/AccountDataService'
import FormTextField from '../../components/control/FormTextField'

type ForgotPasswordFormProps = {
  onFormSubmitted: () => void
  submitButtonText: string
}

export default function ForgotPasswordForm({
  onFormSubmitted,
  submitButtonText,
}: ForgotPasswordFormProps) {
  const { t } = useLingui()
  const [submitSuccess, setSubmitSuccess] = useState('')
  const [submitWarning, setSubmitWarning] = useState('')
  const [submitError, setSubmitError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .label(t`The email address`)
      .required()
      .email(),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(formSchema),
  })

  const handleFormSubmit = (data: { email: string }) => {
    setSubmitSuccess('')
    setSubmitWarning('')
    setSubmitError('')
    setSubmitting(true)

    AccountDataService.sendPasswordResetEmail(data.email.toLowerCase())
      .then(() => {
        setSubmitSuccess(
          t`We have sent an email to you for password changing. Follow the link provided to finalize the sign up process.`,
        )
      })
      .catch((err) => {
        console.log(
          'ERROR: An error occurred while resending verification email',
          err,
          err.response,
        )
        if (err.response.status === 400 && err.response.data === 'email_not_exists') {
          setSubmitWarning(t`This email not exists, please choose another one.`)
        } else {
          setSubmitError(t`An error occurred while resending verification email, please try again.`)
        }
      })
      .finally(() => {
        setSubmitting(false)
        onFormSubmitted()
      })
  }

  return (
    <Box
      component="form"
      noValidate
      onSubmit={handleSubmit(handleFormSubmit)}
      sx={{ mt: 2, width: '100%' }}
    >
      <FormTextField
        errorText={errors.email?.message}
        label={t`Email Address`}
        margin="normal"
        registerReturn={register('email')}
      />
      {submitSuccess !== '' && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {submitSuccess}
        </Alert>
      )}
      {submitWarning !== '' && (
        <Alert severity="warning" sx={{ mt: 2 }}>
          {submitWarning}
        </Alert>
      )}
      {submitError !== '' && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {submitError}
        </Alert>
      )}
      <Button color="primary" fullWidth sx={{ mb: 2, mt: 3 }} type="submit" variant="contained">
        {submitButtonText}
      </Button>
      <Grid2 container justifyContent="flex-end">
        <Grid2>
          <Link href="/auth/login/" variant="body2">
            {t`Sign In`}
          </Link>
        </Grid2>
      </Grid2>
      <ProgressBackdrop open={submitting} />
    </Box>
  )
}
