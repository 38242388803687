import React, { useCallback, useRef, useState } from 'react'
import { useLingui } from '@lingui/react/macro'

import { Box, Button, Divider, SvgIcon, Typography } from '@mui/material'
import {
  ViewHeadline,
  InsertChartOutlined,
  TableRowsOutlined,
  ViewWeekOutlined,
  DeleteOutlined,
  Addchart,
  Downloading,
  Terminal,
  SvgIconComponent,
  CheckBoxOutlined,
  InputOutlined,
  ContentCopyOutlined,
  RuleOutlined,
} from '@mui/icons-material'

import { KeyedMutator } from 'swr'

import ActionMenuAddToolItem from 'components/action-menu/ActionMenuAddToolItem'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import FileUploadDialog from 'components/dialog/FileUploadDialog'
import TemplateDialog from 'components/control/TemplateDialog'

import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { IClipboard, IClipboardItemState } from 'contexts/ClipboardContext'
import { useErrorContext } from 'contexts/ErrorContext'
import { stationMutate, toolMutate } from 'hooks/studyMutations'

import StudyRepository from 'repositories/StudyRepository'
import StationDataService from 'data-services/StationDataService'
import ToolDataService from 'data-services/ToolDataService'

import orderingHelper from 'utils/orderingHelper'
import paths from 'utils/paths'
import { createNewTool } from 'helpers/createNewTool'

import { colors } from 'shared/theme'
import DoubleArrowRightIcon from 'assets/icons/double-arrow-right.svg?react'
import PushRightIcon from 'assets/icons/push-right.svg?react'
import PushDownIcon from 'assets/icons/push-down.svg?react'

import IStationData from 'types/IStationData'
import IStudyData, {
  IPasteDataInterface,
  IStudyGridItemData,
  IStudyImportData,
} from 'types/IStudyData'
import ITermData from 'types/ITermData'
import IToolData, { getToolTypes, ToolImportSchema } from 'types/IToolData'

type ActionMenuToolProps = {
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
}

export default function ActionMenuTool({ mutateStudy, clipboard }: ActionMenuToolProps) {
  const { t } = useLingui()
  const {
    actionMenuContext,
    setActionMenuContext,
    isContextMenuLoading,
    setContextMenuLoading,
    resetActionMenuContext,
  } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()
  const actionButtonAddToolRef = useRef<HTMLButtonElement>(null)
  const [actionButtonAddToolOpen, setActionButtonAddToolOpen] = useState(false)
  const actionButtonDeleteRef = useRef<HTMLButtonElement>(null)
  const [actionButtonDeleteOpen, setActionButtonDeleteOpen] = useState(false)
  const actionButtonPushToolRef = useRef<HTMLButtonElement>(null)
  const actionButtonCopyMoveRef = useRef<HTMLButtonElement>(null)
  const [actionButtonCopyMoveOpen, setActionButtonCopyMoveOpen] = useState(false)
  const actionButtonCopyMoveTermRef = useRef<HTMLButtonElement>(null)
  const [actionButtonCopyMoveTermOpen, setActionButtonCopyMoveTermOpen] = useState(false)
  const [actionButtonPushToolOpen, setActionButtonPushToolOpen] = useState(false)
  const [importDialogOpen, setImportDialogOpen] = useState(false)
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false)
  const toolTypes = getToolTypes()

  const termStates: IClipboardItemState<ITermData>[] = clipboard.terms.getSelectedItemStates()
  const toolTermStates = termStates.filter(
    (termState) => termState.data.tool === actionMenuContext.tool?.id,
  )
  StudyRepository.initialize(handleAsyncError)

  const addTool = (newTool: IToolData): Promise<IToolData> =>
    new Promise<IToolData>((resolve, reject) => {
      ToolDataService.addTool(newTool)
        .then(({ data }) => {
          resolve(data)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const removeStationGridItem = (data: IStudyGridItemData): Promise<IStationData> =>
    new Promise<IStationData>((resolve, reject) => {
      StationDataService.removeStationGridItem(actionMenuContext.station.id, data)
        .then(({ data: stationResponse }) => {
          void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          resolve(stationResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const deleteTool = (toolId: string): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      ToolDataService.deleteTool(toolId)
        .then(() => {
          resolve(true)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const pushTool = (data: IStudyGridItemData): Promise<IStationData> =>
    new Promise<IStationData>((resolve, reject) => {
      ToolDataService.pushTool(actionMenuContext.tool.id, data)
        .then(({ data: stationResponse }) => {
          void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          resolve(stationResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const importToolFromTemplate = (templateId: string): Promise<IStationData> =>
    new Promise<IStationData>((resolve, reject) => {
      const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.toolGridItem)
      StationDataService.importToolFromTemplateStation(actionMenuContext.station.id, {
        row,
        column,
        template_id: templateId,
      })
        .then(({ data: stationResponse }) => {
          void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          const newTool = stationResponse.tools.find(
            (tool) => tool.row === row && tool.column === column,
          )
          if (newTool) {
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              tool: newTool,
            }))
          }
          setActionButtonPushToolOpen(false)
          resolve(stationResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const importToolFromFile = (data: IStudyImportData): Promise<IStationData> =>
    new Promise<IStationData>((resolve, reject) => {
      StationDataService.importToolFromFileStation(actionMenuContext.station.id, data)
        .then(({ data: stationResponse }) => {
          void mutateStudy(stationMutate(stationResponse.id, stationResponse), false)
          resolve(stationResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const deleteToolTerms = (toolId: string): Promise<IToolData> =>
    new Promise<IToolData>((resolve, reject) => {
      ToolDataService.deleteToolTerms(toolId)
        .then(({ data: toolResponse }) => {
          void mutateStudy(toolMutate(toolResponse.station, toolResponse.id, toolResponse), false)
          resolve(toolResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const pasteTools = (data: IPasteDataInterface): Promise<IStudyData> =>
    new Promise<IStudyData>((resolve, reject) => {
      ToolDataService.pasteTools(actionMenuContext.station.id, data)
        .then(({ data: studyResponse }) => {
          resolve(studyResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleRemoveStationGridItem = (target: 'row' | 'column') => {
    const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.toolGridItem)
    setContextMenuLoading(true)
    removeStationGridItem({
      target,
      selected_column: column,
      selected_row: row,
    })
      .then((stationResponse) => {
        const newTool = orderingHelper.findToolByRowColumn(stationResponse, row, column)
        if (newTool)
          setActionMenuContext((prevActionMenuContext) => ({
            ...prevActionMenuContext,
            tool: newTool,
          }))
      })
      .finally(() => setContextMenuLoading(false))
    setActionButtonDeleteOpen(false)
  }

  const handleAddToolClick = (toolType: string) => {
    if (actionMenuContext.station && actionMenuContext.toolGridItem) {
      const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.toolGridItem)
      const tool = orderingHelper.findToolByRowColumn(actionMenuContext.station, row, column)

      if (!tool) {
        const newTool = createNewTool(actionMenuContext.station.id, toolType, row, column)
        setContextMenuLoading(true)
        addTool(newTool).then((newToolResponse) => {
          mutateStudy()
            .then(() => {
              setActionMenuContext((prevActionMenuContext) => ({
                ...prevActionMenuContext,
                tool: newToolResponse,
              }))
            })
            .finally(() => setContextMenuLoading(false))
        })
      }
    }
    setActionButtonAddToolOpen(false)
  }

  const handleDeleteTool = () => {
    if (actionMenuContext.tool) {
      setContextMenuLoading(true)
      deleteTool(actionMenuContext.tool.id).then(() => {
        mutateStudy()
          .then(() => {
            setActionMenuContext((prevActionMenuContext) => ({
              ...prevActionMenuContext,
              station: {
                ...actionMenuContext.station,
                tools: actionMenuContext.station.tools.filter(
                  (toolObject) => toolObject.id !== actionMenuContext.tool.id,
                ),
              },
              tool: null,
            }))
          })
          .finally(() => setContextMenuLoading(false))
      })
    }
    setActionButtonDeleteOpen(false)
  }

  const handlePushTool = (direction: 'bottom' | 'right') => {
    setContextMenuLoading(true)
    pushTool({
      direction,
    })
      .then((stationResponse) => {
        setActionMenuContext((prevActionMenuContext) => {
          const newTool = stationResponse.tools.find(
            (tool) => actionMenuContext.tool.id === tool.id,
          )
          return {
            ...prevActionMenuContext,
            toolGridItem: `${actionMenuContext.station.id}-tool-droppable-${newTool.row}-${newTool.column}`,
          }
        })
      })
      .finally(() => setContextMenuLoading(false))
    setActionButtonPushToolOpen(false)
  }

  const handleImportToolFromFile = useCallback(
    (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>): void => {
      if (actionMenuContext && actionMenuContext.toolGridItem) {
        ToolImportSchema.validate(JSON.parse(json), { abortEarly: false, stripUnknown: true })
          .then((value: unknown) => {
            const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.toolGridItem)
            const templateTool: IToolData = value as IToolData
            importToolFromFile({
              row,
              column,
              tool: templateTool,
            }).then(() => {
              setImportDialogOpen(false)
            })
          })
          .catch(({ errors }) => {
            setErrorList([t`Invalid file format`, ...errors])
          })
      }
    },
    [],
  )

  const handlePasteFromClipboardSelection = (action: 'copy' | 'cut'): void => {
    setContextMenuLoading(true)
    const toolStates: IClipboardItemState<IToolData>[] = clipboard.tools.getSelectedItemStates()
    if (!actionMenuContext || !actionMenuContext.toolGridItem || toolStates.length === 0) {
      setContextMenuLoading(false)
      return
    }

    const { row, column } = orderingHelper.getRowColumnByKey(actionMenuContext.toolGridItem)
    const tools = clipboard.tools.getSelectedItemStates()
    const toolPasteData: IPasteDataInterface = {
      row,
      column,
      actions: tools.map((tool) => ({
        action,
        tool: tool.data.id,
      })),
    }

    pasteTools(toolPasteData)
      .then((studyResponse: IStudyData) => {
        mutateStudy(studyResponse, false).then(() => {
          clipboard.tools.removeSelectedItemStates()
          resetActionMenuContext()
        })
      })
      .finally(() => setContextMenuLoading(false))
  }

  const handlePasteToClipboard = (action: 'copy' | 'cut'): void => {
    setContextMenuLoading(true)

    const termStates: IClipboardItemState<ITermData>[] = clipboard.terms.getSelectedItemStates()

    if (!actionMenuContext || !actionMenuContext.tool || termStates.length === 0) {
      setContextMenuLoading(false)
      return
    }

    const terms = clipboard.terms.getSelectedItemStates()
    let clippy = ''
    for (let i = 0; i < terms.length; i += 1) clippy += `${terms[i].data.term}\n`
    navigator.clipboard.writeText(clippy)
    clipboard.terms.removeSelectedItemStates()
    resetActionMenuContext()
    setContextMenuLoading(false)
  }

  const handlePasteFromClipboardSelectionTerms = (action: 'copy' | 'cut'): void => {
    setContextMenuLoading(true)

    const termStates: IClipboardItemState<ITermData>[] = clipboard.terms.getSelectedItemStates()

    if (!actionMenuContext || !actionMenuContext.tool || termStates.length === 0) {
      setContextMenuLoading(false)
      return
    }

    const terms = clipboard.terms.getSelectedItemStates()
    const toolPasteData: IPasteDataInterface = {
      term: actionMenuContext.term?.id ?? null,
      actions: terms.map((term) => ({
        action,
        term: term.data.id,
      })),
    }

    StudyRepository.pasteTerms(actionMenuContext.tool.id, toolPasteData)
      .then((studyResponse: IStudyData) => {
        mutateStudy(studyResponse, false).then(() => {
          clipboard.terms.removeSelectedItemStates()
          resetActionMenuContext()
        })
      })
      .finally(() => setContextMenuLoading(false))
  }

  const handleImportToolFromFileDialog = (): void => {
    setActionButtonAddToolOpen(false)
    setImportDialogOpen(true)
  }

  const handleImportToolFromTemplateDialog = (): void => {
    setActionButtonAddToolOpen(false)
    setTemplateDialogOpen(true)
  }

  const handleDeleteAllTerms = () => {
    if (actionMenuContext.tool) {
      setContextMenuLoading(true)
      deleteToolTerms(actionMenuContext.tool.id).finally(() => setContextMenuLoading(false))
    }
    setActionButtonDeleteOpen(false)
  }

  const handleDeleteSelectedTermsInTool = async () => {
    if (actionMenuContext.tool) {
      setContextMenuLoading(true)

      for (const toolTermState of toolTermStates) {
        await StudyRepository.deleteTerm(toolTermState.data.id)
      }

      for (const toolTermState of toolTermStates) {
        clipboard.terms.removeItem(toolTermState.data.id)
      }
      mutateStudy().then((studyResponse) => {
        const newStation = studyResponse.stations.find(
          (stationObject) => stationObject.id === actionMenuContext.station.id,
        )
        const newTool = newStation?.tools.find(
          (toolObject) => toolObject.id === actionMenuContext.tool.id,
        )
        setActionMenuContext((prevActionMenuContext) => ({
          ...prevActionMenuContext,
          tool: newTool,
        }))
      })
      setContextMenuLoading(false)
    }
    setActionButtonDeleteOpen(false)
  }

  return (
    <Box
      display="flex"
      gap={{ xs: '8px', mobile: '32px' }}
      flexGrow="1"
      alignItems="center"
      flexWrap={{ xs: 'wrap', mobile: 'nowrap' }}
    >
      {!actionMenuContext.tool && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonAddToolRef}
          actionMenuButtonAnchorOpen={actionButtonAddToolOpen}
          setActionMenuButtonAnchorOpen={setActionButtonAddToolOpen}
          handleActionMenuButtonClick={() => setActionButtonAddToolOpen(!actionButtonAddToolOpen)}
          StartIcon={Addchart}
          title={t`New Tool`}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
              {toolTypes.map((toolType) => (
                <ActionMenuAddToolItem
                  key={toolType.id}
                  toolType={toolType.id}
                  toolName={toolType.text}
                  handleAddToolClick={() =>
                    handleAddToolClick(toolType.id === 'blank' ? '' : toolType.id)
                  }
                />
              ))}

              <Divider sx={{ borderColor: colors.lightBlue.A600 }} />

              <Button
                onClick={handleImportToolFromFileDialog}
                variant="text"
                type="button"
                disableRipple
                startIcon={<Downloading sx={{ color: colors.black.A500 }} />}
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`From File`}
                </Typography>
              </Button>

              <Button
                onClick={handleImportToolFromTemplateDialog}
                variant="text"
                type="button"
                disableRipple
                startIcon={<Terminal sx={{ color: colors.black.A500 }} />}
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`From Template`}
                </Typography>
              </Button>
            </Box>
          }
        />
      )}

      {actionMenuContext && actionMenuContext.tool && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonPushToolRef}
          actionMenuButtonAnchorOpen={actionButtonPushToolOpen}
          setActionMenuButtonAnchorOpen={setActionButtonPushToolOpen}
          handleActionMenuButtonClick={() => setActionButtonPushToolOpen(!actionButtonPushToolOpen)}
          StartIcon={DoubleArrowRightIcon as unknown as SvgIconComponent}
          title={t`Push Tool`}
          minWidth={{ xs: '160px' }}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 8px" flexDirection="column">
              <Button
                onClick={() => handlePushTool('right')}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <SvgIcon sx={{ color: colors.black.A500 }}>
                    <PushRightIcon />
                  </SvgIcon>
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Push Right`}
                </Typography>
              </Button>

              <Button
                onClick={() => handlePushTool('bottom')}
                variant="text"
                type="button"
                disableRipple
                startIcon={
                  <SvgIcon sx={{ color: colors.black.A500 }}>
                    <PushDownIcon />
                  </SvgIcon>
                }
                sx={{ width: '100%', padding: '8px 24px' }}
              >
                <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                  {t`Push Down`}
                </Typography>
              </Button>
            </Box>
          }
        />
      )}

      {clipboard.tools.getSelectedItemStates().length > 0 && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonCopyMoveRef}
          actionMenuButtonAnchorOpen={actionButtonCopyMoveOpen}
          setActionMenuButtonAnchorOpen={setActionButtonCopyMoveOpen}
          handleActionMenuButtonClick={() => setActionButtonCopyMoveOpen(!actionButtonCopyMoveOpen)}
          StartIcon={CheckBoxOutlined}
          title={`${t`Selected Tools`} (${clipboard.tools.getSelectedItemStates().length})`}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelection('cut')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <InputOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {!actionMenuContext.tool ? t`Cut and Paste` : t`Cut and Paste`}
                  </Typography>
                </Button>
              </Box>

              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelection('copy')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ContentCopyOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {!actionMenuContext.tool ? t`Copy and Paste` : t`Copy and Paste`}
                  </Typography>
                </Button>
              </Box>
            </Box>
          }
        />
      )}

      {actionMenuContext.tool && clipboard.terms.getSelectedItemStates().length > 0 && (
        <ActionMenuButton
          actionMenuButtonRef={actionButtonCopyMoveTermRef}
          actionMenuButtonAnchorOpen={actionButtonCopyMoveTermOpen}
          setActionMenuButtonAnchorOpen={setActionButtonCopyMoveTermOpen}
          handleActionMenuButtonClick={() =>
            setActionButtonCopyMoveTermOpen(!actionButtonCopyMoveTermOpen)
          }
          StartIcon={CheckBoxOutlined}
          title={`${t`Selected Terms`} (${clipboard.terms.getSelectedItemStates().length})`}
          isButtonDisabled={isContextMenuLoading}
          actionMenuItems={
            <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelectionTerms('cut')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <InputOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {!actionMenuContext.tool ? t`Cut and Paste` : t`Cut and Paste`}
                  </Typography>
                </Button>
              </Box>

              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteFromClipboardSelectionTerms('copy')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ContentCopyOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {!actionMenuContext.tool ? t`Copy and Paste` : t`Copy and Paste`}
                  </Typography>
                </Button>
              </Box>
              <Box display="flex" gap="8px" flexDirection="column">
                <Button
                  onClick={() => handlePasteToClipboard('copy')}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ContentCopyOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                    {t`Copy to clipboard`}
                  </Typography>
                </Button>
              </Box>
            </Box>
          }
        />
      )}

      <ActionMenuButton
        actionMenuButtonRef={actionButtonDeleteRef}
        actionMenuButtonAnchorOpen={actionButtonDeleteOpen}
        setActionMenuButtonAnchorOpen={setActionButtonDeleteOpen}
        handleActionMenuButtonClick={() => setActionButtonDeleteOpen(!actionButtonDeleteOpen)}
        StartIcon={DeleteOutlined}
        title={t`Delete`}
        isDanger
        isButtonDisabled={isContextMenuLoading}
        actionMenuItems={
          <Box display="flex" gap="8px" padding="0 16px" flexDirection="column">
            {actionMenuContext && actionMenuContext.tool && (
              <Box display="flex" gap="8px" flexDirection="column">
                {toolTermStates.length > 0 && (
                  <Button
                    onClick={handleDeleteSelectedTermsInTool}
                    variant="text"
                    type="button"
                    disableRipple
                    startIcon={
                      <RuleOutlined
                        sx={{ color: colors.black.A500 }}
                        className="MuiButton-startIcon-danger"
                      />
                    }
                    sx={{ width: '100%', padding: '8px 24px' }}
                  >
                    <Typography
                      color="textPrimary"
                      textAlign="left"
                      margin="0 0 0 8px"
                      className="MuiTypography-danger"
                    >
                      {t`All selected terms in Tool`}
                    </Typography>
                  </Button>
                )}

                <Button
                  onClick={handleDeleteAllTerms}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <ViewHeadline
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon-danger"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography
                    color="textPrimary"
                    textAlign="left"
                    margin="0 0 0 8px"
                    className="MuiTypography-danger"
                  >
                    {t`All terms in Tool`}
                  </Typography>
                </Button>

                <Button
                  onClick={() => handleDeleteTool()}
                  variant="text"
                  type="button"
                  disableRipple
                  startIcon={
                    <InsertChartOutlined
                      sx={{ color: colors.black.A500 }}
                      className="MuiButton-startIcon-danger"
                    />
                  }
                  sx={{ width: '100%', padding: '8px 24px' }}
                >
                  <Typography
                    color="textPrimary"
                    textAlign="left"
                    margin="0 0 0 8px"
                    className="MuiTypography-danger"
                  >
                    {t`Tool`}
                  </Typography>
                </Button>
              </Box>
            )}

            <Button
              onClick={() => handleRemoveStationGridItem('column')}
              variant="text"
              type="button"
              disableRipple
              startIcon={
                <ViewWeekOutlined
                  sx={{ color: colors.black.A500 }}
                  className="MuiButton-startIcon-danger"
                />
              }
              sx={{ width: '100%', padding: '8px 24px' }}
            >
              <Typography
                color="textPrimary"
                textAlign="left"
                margin="0 0 0 8px"
                className="MuiTypography-danger"
              >
                {t`Tool Column`}
              </Typography>
            </Button>

            <Button
              onClick={() => handleRemoveStationGridItem('row')}
              variant="text"
              type="button"
              disableRipple
              startIcon={
                <TableRowsOutlined
                  sx={{ color: colors.black.A500 }}
                  className="MuiButton-startIcon-danger"
                />
              }
              sx={{ width: '100%', padding: '8px 24px' }}
            >
              <Typography
                color="textPrimary"
                textAlign="left"
                margin="0 0 0 8px"
                className="MuiTypography-danger"
              >
                {t`Tool Row`}
              </Typography>
            </Button>
          </Box>
        }
      />

      <FileUploadDialog
        description={t`Click or drag to import tool`}
        open={importDialogOpen}
        onClose={() => setImportDialogOpen(false)}
        onUpload={handleImportToolFromFile}
        title={actionMenuContext.station.title}
      />

      {templateDialogOpen && (
        <TemplateDialog
          description={t`Add a tool (including its terms) from a template.`}
          templatesEndpoint={paths.backend.study.tool.toolsTemplates}
          open={templateDialogOpen}
          onClose={() => setTemplateDialogOpen(false)}
          onImportFromTemplate={importToolFromTemplate}
          title={t`Import tool from template`}
        />
      )}
    </Box>
  )
}
