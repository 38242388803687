import useSWR, { KeyedMutator } from 'swr'
import { AxiosResponse } from 'axios'
import IStudyListData from 'types/IStudyListData'
import paths from 'utils/paths'

type MutateFunction<Data> = KeyedMutator<Data>

function useStudyLists(): {
  studyLists: AxiosResponse<IStudyListData[]> | undefined
  mutateStudyLists: MutateFunction<AxiosResponse<IStudyListData[]>>
  isLoading: boolean
} {
  const {
    data: studyLists,
    mutate: mutateStudyLists,
    isLoading,
  } = useSWR<AxiosResponse<IStudyListData[]>>(paths.backend.studyList.studyLists())

  return { studyLists, mutateStudyLists, isLoading }
}

export { useStudyLists }
