import { Dispatch, SetStateAction } from 'react'
import axios from 'axios'
import { t } from '@lingui/core/macro'
import { defaultLocale } from './i18n'
import { IAuthData } from '../types/IUserData'

const baseURL = `${import.meta.env.VITE_BACKEND_URI}/api/`
const headerToken = 'Bearer '

interface IAxiosObserver {
  appState: Dispatch<SetStateAction<string>> | null
  errorStateEnabled: boolean
  errorState: Dispatch<SetStateAction<string>> | null
}

export const axiosObserver: IAxiosObserver = {
  appState: null,
  errorStateEnabled: true,
  errorState: null,
}

const axiosInstance = axios.create({
  baseURL,
  timeout: 60000, // 60 seconds
  headers: {
    Authorization: localStorage.getItem('access_token')
      ? headerToken + localStorage.getItem('access_token')
      : null,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  (config) =>
    /* const apiDelay = import.meta.env.VITE_API_DELAY ? parseInt(import.meta.env.VITE_API_DELAY) : 0
    if (apiDelay > 0) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(config), apiDelay)
      })
    } */
    config,
  (error) => {
    Promise.reject(error)
  },
)

export const setLocalToken = (authData: IAuthData): void => {
  localStorage.setItem('access_token', authData.access)
  localStorage.setItem('refresh_token', authData.refresh)
  localStorage.setItem('user_name', authData.user.name)
  axiosInstance.defaults.headers.Authorization = headerToken + authData.access
}

export const unsetLocalToken = (): void => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('user_name')
  axiosInstance.defaults.headers.Authorization = null
}

axiosInstance.interceptors.request.use((request) => {
  if (axiosObserver.appState) {
    axiosObserver.appState(t`.`)
  }
  request.headers['Accept-Language'] = `${defaultLocale}-ca`
  return request
})

axiosInstance.interceptors.response.use(
  (response) => {
    if (axiosObserver.appState) {
      axiosObserver.appState('')
    }
    return response
  },
  async (error) => {
    if (axiosObserver.appState) {
      axiosObserver.appState('')
    }
    if (axiosObserver.errorStateEnabled && axiosObserver.errorState) {
      axiosObserver.errorState('')
    }

    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status === 401) {
          if (error.response.data.code === 'token_not_valid') {
            const refreshToken: string | null = localStorage.getItem('refresh_token')

            if (refreshToken) {
              const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]))

              // exp date in token is expressed in seconds, while now() returns milliseconds:
              const now = Math.ceil(Date.now() / 1000)

              if (tokenParts.exp > now) {
                try {
                  const response = await axiosInstance.post('auth/refresh/', {
                    refresh: refreshToken,
                  })
                  console.log('Axios - Access token refreshed')
                  localStorage.setItem('access_token', response.data.access)
                  if (response.data.refresh) {
                    localStorage.setItem('refresh_token', response.data.refresh)
                  }

                  axiosInstance.defaults.headers.Authorization = headerToken + response.data.access
                  error.config.headers.Authorization = headerToken + response.data.access
                  return await axiosInstance(error.config)
                } catch (err) {
                  console.log('Axios error handler - auth/refresh/', err, err.response)
                }
              }
              unsetLocalToken() // Unnecessary 'else' after 'return'
            }
          }
          if (error.config.url !== 'auth/login/') {
            window.location.href = '/auth/login/'
          }
        }
      }
    }

    // todo: backend sends meaningful and translated error.
    if (
      error?.response?.status !== 401 &&
      error?.name !== 'CanceledError' &&
      axiosObserver?.errorStateEnabled &&
      axiosObserver?.errorState
    ) {
      axiosObserver?.errorState(t`Unexpected result, please try again.`)
    }
    return Promise.reject(error)
  },
)

export default axiosInstance
