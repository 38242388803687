import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Alert, Box, Button, CircularProgress, Grid2, Link, Typography } from '@mui/material'
import { useLingui } from '@lingui/react/macro'
import { ProgressBackdrop } from '../../components/control/ProgressBackdrop'
import AccountDataService from '../../data-services/AccountDataService'
import IUserData from '../../types/IUserData'
import ForgotPasswordForm from '../account/ForgotPasswordForm'
import FormTextField from '../../components/control/FormTextField'

type FormResetPasswordFields = {
  password: string
  confirmPassword: string
}

export default function ResetPassword() {
  const { t } = useLingui()

  const navigate = useNavigate()
  const [key] = useState<string | null>((): string | null => {
    const urlParams = new URLSearchParams(window.location.search)
    const k = urlParams?.get('k')
    if (k) {
      return k
    }
    return null
  })
  const [isValidKey, setIsValidKey] = useState<boolean | null>(null)
  const [user, setUser] = useState<IUserData | null>(null)
  const [submitError, setSubmitError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const formSchema = yup.object().shape({
    password: yup
      .string()
      .label(t`The password`)
      .required()
      .max(100)
      .min(8),
    confirmPassword: yup
      .string()
      .label(t`The confirm password`)
      .required()
      .oneOf([yup.ref('password')], t`Passwords does not match`),
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(formSchema),
  })

  useEffect(() => {
    if (key) {
      AccountDataService.getUserInfoFromPasswordResetKey(key)
        .then((response) => {
          const user: IUserData | null = response.data
          console.log('Successful getting user information', user)
          if (user !== null) {
            setIsValidKey(true)
            setUser(user)
          }
        })
        .catch((err) => {
          console.log(
            'ERROR: An error occurred during getting user info from key',
            err,
            err.response,
          )
          if (err.response.status === 400 && err.response.data === 'expired_or_invalid_link') {
            setIsValidKey(false)
            setSubmitError(t`This password reset link has expired or is invalid.`)
          } else {
            setSubmitError(t`An error occurred while getting user info, please try again.`)
          }
        })
    } else {
      setIsValidKey(false)
    }
  }, [])

  const handleFormSubmit = (data: FormResetPasswordFields) => {
    setSubmitError('')

    if (key) {
      setSubmitting(true)
      AccountDataService.resetPassword(key, data.password)
        .then((response) => {
          const user: IUserData = response.data
          console.log('Password reset successful', user)
          navigate('/auth/login/')
        })
        .catch((err) => {
          console.log('ERROR: An error occurred while setting the new password', err, err.response)
          setSubmitError(t`An error occurred while setting the new password, please try again.`)
        })
        .finally(() => {
          setSubmitting(false)
        })
    }
  }

  const handleFormSubmitted = () => {
    setSubmitError('')
  }

  return (
    <>
      <Typography component="h1" variant="h6">
        {t`Change your password`}
      </Typography>
      {isValidKey === null || isValidKey === true ? (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{ width: '100%' }}
        >
          <Box sx={{ mb: 2, mt: 3, textAlign: 'center' }}>
            {isValidKey === null && submitError === '' && <CircularProgress sx={{ p: 0.5 }} />}
            {isValidKey === true && submitError === '' && (
              <span>{t`You can choose a new password for user ${user?.name} (${user?.email}).`}</span>
            )}
          </Box>
          <FormTextField
            disabled={isValidKey !== true}
            errorText={errors.password?.message}
            label={t`Password`}
            margin="normal"
            registerReturn={register('password')}
            type="password"
          />
          <Box sx={{ color: '#999', fontSize: '11px' }}>
            {t`Your password must contain at least ${8} characters.`}
          </Box>
          <FormTextField
            disabled={isValidKey !== true}
            errorText={errors.confirmPassword?.message}
            label={t`Confirm Password`}
            margin="normal"
            registerReturn={register('confirmPassword')}
            type="password"
          />
          <Box sx={{ color: '#999', fontSize: '11px' }}>
            {t`Enter the same password as before, for verification.`}
          </Box>
          {submitError !== '' && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {submitError}
            </Alert>
          )}
          <Button
            color="primary"
            disabled={isValidKey !== true}
            fullWidth
            sx={{ mb: 2, mt: 3 }}
            type="submit"
            variant="contained"
          >
            {t`Reset password`}
          </Button>
          <Grid2 container justifyContent="flex-end">
            <Grid2>
              <Link href="/auth/login/" variant="body2">
                {t`Sign In`}
              </Link>
            </Grid2>
          </Grid2>
        </Box>
      ) : (
        <>
          <Box sx={{ mt: 3, textAlign: 'center' }}>
            {t`This password reset link has expired or is invalid. Please send a new password reset request.`}
          </Box>
          <ForgotPasswordForm onFormSubmitted={handleFormSubmitted} submitButtonText={t`Resend`} />
        </>
      )}
      <ProgressBackdrop open={submitting} />
    </>
  )
}
