import { Box, CircularProgress, SxProps } from '@mui/material'

interface LoadingProps {
  sx?: SxProps
}

function Loading({ sx }: LoadingProps) {
  const sxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...sx,
  }

  return (
    <Box sx={sxProps}>
      <CircularProgress />
    </Box>
  )
}

export { Loading }
