import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLingui } from '@lingui/react/macro'

import { Grid2, Typography } from '@mui/material'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable'

import { MutatorCallback } from 'swr'

import IStudyData from 'types/IStudyData'

import StudyDataService from 'data-services/StudyDataService'

import { SortableStudyCard } from './SortableStudyCard'

interface DragAndDropStudiesProps {
  mutateStudyList: MutatorCallback
  setSelectedStudy: Dispatch<SetStateAction<IStudyData>>
  setStudyDialogOpen: Dispatch<SetStateAction<boolean>>
  studies: IStudyData[]
  studyListId: string
}

function DragAndDropStudies({
  mutateStudyList,
  setSelectedStudy,
  setStudyDialogOpen,
  studies,
  studyListId,
}: DragAndDropStudiesProps) {
  const { t } = useLingui()
  const [items, setItems] = useState(studies)

  useEffect(() => {
    setItems(studies)
  }, [studies])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const moveItems = (activeId: UniqueIdentifier, overId: UniqueIdentifier) => {
    const oldPosition = items.find(({ id }) => id === activeId).order
    const newPosition = items.find(({ id }) => id === overId).order

    StudyDataService.moveStudy(String(activeId), {
      destination_folder: studyListId,
      destination_index: newPosition,
    })

    return arrayMove(items, oldPosition, newPosition)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over.id) {
      const movedItems = moveItems(active.id, over.id)

      const newOrderStudies = movedItems.map((study, index) => ({
        ...study,
        order: index,
      }))

      setItems(newOrderStudies)
    }
  }

  return (
    <>
      <Typography variant="h3">{t`Boards`}</Typography>
      <Grid2 container spacing={2} sx={{ marginTop: '24px' }}>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={rectSortingStrategy}>
            {items.map((study) => (
              <Grid2 size={{ xs: 12, sm: 6 }} key={`study-${study.id}`}>
                <SortableStudyCard
                  studyListId={studyListId}
                  study={study}
                  mutator={mutateStudyList}
                  setStudyDialogOpen={setStudyDialogOpen}
                  setSelectedStudy={setSelectedStudy}
                />
              </Grid2>
            ))}
          </SortableContext>
        </DndContext>
      </Grid2>
    </>
  )
}

export { DragAndDropStudies }
