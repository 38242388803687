import React, { useCallback, useRef, useState } from 'react'
import { Box, Button, Divider, Typography } from '@mui/material'
import ActionMenuButton from 'components/action-menu/ActionMenuButton'
import {
  MoreHoriz as MoreHorizIcon,
  DriveFolderUpload as DriveFolderUploadIcon,
  Downloading as DownloadingIcon,
  SettingsOutlined as SettingsIcon,
  TextFields as TextFieldsIcon,
  ChecklistRtlOutlined as ChecklistRtlIcon,
} from '@mui/icons-material'
import { useLingui } from '@lingui/react/macro'
import { useActionMenuContext } from 'contexts/ActionMenuContext'
import { colors } from 'shared/theme'
import IToolData, { serializeTool } from 'types/IToolData'
import FileUploadDialog from 'components/dialog/FileUploadDialog'
import ITermData, { TermImportSchema } from 'types/ITermData'
import IStudyData, { IStudyImportData } from 'types/IStudyData'
import { toolMutate } from 'hooks/studyMutations'
import ToolDataService from 'data-services/ToolDataService'
import { KeyedMutator } from 'swr'
import { useErrorContext } from 'contexts/ErrorContext'
import ToolConfigDialog from 'components/dialog/ToolDialog'
import ToolCopyDialog from 'components/dialog/ToolCopyDialog'
import { IClipboard } from 'contexts/ClipboardContext'

type ActionMenuToolRightProps = {
  mutateStudy: KeyedMutator<IStudyData>
  clipboard: IClipboard
}

export default function ActionMenuToolRight({ mutateStudy, clipboard }: ActionMenuToolRightProps) {
  const { t } = useLingui()
  const { actionMenuContext, isContextMenuLoading, setContextMenuLoading } = useActionMenuContext()
  const { handleAsyncError } = useErrorContext()
  const actionButtonMoreActionRef = useRef<HTMLButtonElement>(null)
  const [actionButtonMoreActionOpen, setActionButtonMoreActionOpen] = useState(false)
  const [importDialogOpen, setImportDialogOpen] = useState(false)
  const [configToolDialogOpen, setConfigToolDialogOpen] = useState(false)
  const [configToolCopyDialogOpen, setConfigToolCopyDialogOpen] = useState(false)

  const importTermFromFile = (data: IStudyImportData): Promise<IToolData> =>
    new Promise<IToolData>((resolve, reject) => {
      ToolDataService.importTermFromFileTool(actionMenuContext.tool.id, data)
        .then(({ data: toolResponse }) => {
          void mutateStudy(
            toolMutate(actionMenuContext.tool.station, actionMenuContext.tool.id, toolResponse),
            false,
          )
          resolve(toolResponse)
        })
        .catch((err) => {
          handleAsyncError(err.message)
          reject(err)
        })
    })

  const handleExportToolToFile = (): void => {
    setActionButtonMoreActionOpen(false)
  }

  const handleImportTermFromFileDialog = (): void => {
    setActionButtonMoreActionOpen(false)
    setImportDialogOpen(true)
  }

  const handleImportTermFromFile = useCallback(
    (json: string, setErrorList: React.Dispatch<React.SetStateAction<string[]>>): void => {
      if (actionMenuContext && actionMenuContext.tool) {
        TermImportSchema.validate(JSON.parse(json), { abortEarly: false, stripUnknown: true })
          .then((value: unknown) => {
            const templateTerm: ITermData = value as ITermData
            importTermFromFile({
              term: templateTerm,
            }).then(() => {
              setImportDialogOpen(false)
            })
          })
          .catch(({ errors }) => {
            setErrorList([t`Invalid file format`, ...errors])
          })
      }
    },
    [],
  )

  const handleCloseConfigToolDialog = (): void => {
    setConfigToolDialogOpen(false)
  }

  const handleOpenConfigToolDialog = (): void => {
    setActionButtonMoreActionOpen(false)
    setConfigToolDialogOpen(true)
  }

  const handleCloseConfigToolCopyDialog = (): void => {
    setConfigToolCopyDialogOpen(false)
  }

  const handleOpenConfigToolCopyDialog = (): void => {
    setActionButtonMoreActionOpen(false)
    setConfigToolCopyDialogOpen(true)
  }

  const handleSelectAllTermsInTool = async () => {
    if (actionMenuContext.tool) {
      setContextMenuLoading(true)

      for (const toolTerm of actionMenuContext.tool.terms) {
        clipboard.terms.addItem('copy', toolTerm)
      }

      setContextMenuLoading(false)
    }
  }

  return (
    <Box width={{ xs: '100%', mobile: 'auto' }}>
      {actionMenuContext &&
        actionMenuContext.tool &&
        !actionMenuContext.termGridItem &&
        !actionMenuContext.term && (
          <Box
            display="flex"
            width="100%"
            gap={{ xs: '8px', mobile: '32px' }}
            sx={{ flexDirection: { xs: 'column', mobile: 'row' } }}
          >
            <ActionMenuButton
              handleActionMenuButtonClick={handleSelectAllTermsInTool}
              StartIcon={ChecklistRtlIcon}
              title={t`Select All Terms`}
            />

            <ActionMenuButton
              actionMenuButtonRef={actionButtonMoreActionRef}
              actionMenuButtonAnchorOpen={actionButtonMoreActionOpen}
              setActionMenuButtonAnchorOpen={setActionButtonMoreActionOpen}
              handleActionMenuButtonClick={() =>
                setActionButtonMoreActionOpen(!actionButtonMoreActionOpen)
              }
              StartIcon={MoreHorizIcon}
              title={t`More Actions`}
              isButtonDisabled={isContextMenuLoading}
              actionMenuItems={
                <Box>
                  <Box display="flex" gap="8px" flexDirection="column" padding="0 16px">
                    <Button
                      onClick={handleOpenConfigToolCopyDialog}
                      variant="text"
                      type="button"
                      disableRipple
                      startIcon={<TextFieldsIcon sx={{ color: colors.black.A500 }} />}
                      sx={{ width: '100%', padding: '8px 24px' }}
                    >
                      <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                        {t`Copy to Excel, Word, etc.`}
                      </Typography>
                    </Button>
                    <Button
                      onClick={handleExportToolToFile}
                      href={`data:text/json;charset=utf-8,${encodeURIComponent(
                        serializeTool(actionMenuContext.tool),
                      )}`}
                      variant="text"
                      disableRipple
                      startIcon={<DriveFolderUploadIcon sx={{ color: colors.black.A500 }} />}
                      sx={{ width: '100%', padding: '8px 24px' }}
                      download={`STS-Tool (${actionMenuContext.tool.title}).json`}
                      component="a"
                    >
                      <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                        {t`Export Tool to File`}
                      </Typography>
                    </Button>
                  </Box>

                  <Box margin="8px 0">
                    <Divider sx={{ borderColor: colors.lightBlue.A600 }} />
                  </Box>

                  <Box display="flex" gap="8px" flexDirection="column" padding="0 16px">
                    <Button
                      onClick={handleImportTermFromFileDialog}
                      variant="text"
                      type="button"
                      disableRipple
                      startIcon={<DownloadingIcon sx={{ color: colors.black.A500 }} />}
                      sx={{ width: '100%', padding: '8px 24px' }}
                    >
                      <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                        {t`Import Term from File`}
                      </Typography>
                    </Button>

                    <Button
                      onClick={handleOpenConfigToolDialog}
                      variant="text"
                      type="button"
                      disableRipple
                      startIcon={<SettingsIcon sx={{ color: colors.black.A500 }} />}
                      sx={{ width: '100%', padding: '8px 24px' }}
                    >
                      <Typography color="textPrimary" textAlign="left" margin="0 0 0 8px">
                        {t`Configure Tool`}
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              }
            />
          </Box>
        )}

      {actionMenuContext && actionMenuContext.tool && (
        <Box>
          {importDialogOpen && (
            <FileUploadDialog
              description={t`Click or drag to import term`}
              open={importDialogOpen}
              onClose={() => setImportDialogOpen(false)}
              onUpload={handleImportTermFromFile}
              title={actionMenuContext.tool.title}
            />
          )}

          {configToolDialogOpen && (
            <ToolConfigDialog
              open={configToolDialogOpen}
              onClose={handleCloseConfigToolDialog}
              tool={actionMenuContext.tool}
              mutateStudy={mutateStudy}
            />
          )}

          {configToolCopyDialogOpen && (
            <ToolCopyDialog
              open={configToolCopyDialogOpen}
              onClose={handleCloseConfigToolCopyDialog}
              tool={actionMenuContext.tool}
            />
          )}
        </Box>
      )}
    </Box>
  )
}
