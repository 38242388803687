import React, { Dispatch, SetStateAction } from 'react'
import { Box, Button, ButtonProps, Typography } from '@mui/material'
import { SvgIconComponent } from '@mui/icons-material'
import { colors } from 'shared/theme'
import PopperComponent from 'components/PopperComponent'

type ActionMenuButtonProps = ButtonProps & {
  actionMenuButtonRef?: React.MutableRefObject<HTMLButtonElement>
  actionMenuButtonAnchorOpen?: boolean
  setActionMenuButtonAnchorOpen?: Dispatch<SetStateAction<boolean>>
  handleActionMenuButtonClick: () => void
  StartIcon: SvgIconComponent
  title: string
  actionMenuItems?: React.ReactNode
  isButtonActive?: boolean
  isButtonDisabled?: boolean
  isDanger?: boolean
  minWidth?:
    | {
        xs?: string | number
        sm?: string | number
        md?: string | number
        lg?: string | number
        xl?: string | number
      }
    | string
    | number
}

export default function ActionMenuButton({
  actionMenuButtonRef,
  actionMenuButtonAnchorOpen,
  setActionMenuButtonAnchorOpen,
  handleActionMenuButtonClick,
  StartIcon,
  title,
  actionMenuItems,
  isButtonActive,
  isButtonDisabled,
  isDanger,
  minWidth,
  sx,
}: ActionMenuButtonProps): JSX.Element {
  const getButtonClassName = (): string => {
    if (isButtonActive || actionMenuButtonAnchorOpen) {
      return isDanger
        ? 'MuiButton-startIcon-danger-active MuiButton-startIcon-fixedHeight'
        : 'MuiButton-startIcon-active MuiButton-startIcon-fixedHeight'
    }

    return isDanger
      ? 'MuiButton-startIcon-danger MuiButton-startIcon-fixedHeight'
      : 'MuiButton-startIcon-fixedHeight'
  }

  const getTypographyClassName = (): string => {
    if (isButtonActive || actionMenuButtonAnchorOpen) {
      return isDanger ? 'MuiTypography-danger-active' : 'MuiTypography-active'
    }

    return isDanger ? 'MuiTypography-danger' : ''
  }

  return (
    <Box sx={{ ...sx, width: { xs: '100%', mobile: 'auto' } }}>
      <Button
        disabled={isButtonDisabled}
        ref={actionMenuButtonRef}
        onClick={handleActionMenuButtonClick}
        variant="textBlock"
        type="button"
        sx={{ width: 'auto', padding: '8px 24px', minWidth: { xs: '100%', mobile: '80px' } }}
        startIcon={<StartIcon fontSize="large" className={getButtonClassName()} />}
      >
        <Typography
          variant="subtitle2"
          color="textPrimary"
          letterSpacing="0.5px"
          className={getTypographyClassName()}
        >
          {title}
        </Typography>
      </Button>

      {actionMenuButtonRef && actionMenuItems && (
        <PopperComponent
          anchorMenu={actionMenuButtonAnchorOpen}
          setAnchorMenu={setActionMenuButtonAnchorOpen}
          anchorRef={actionMenuButtonRef}
          backgroundColor={colors.white.A500}
          sx={{ minWidth: minWidth ?? '100px', zIndex: 1000, padding: '0 15px' }}
        >
          {actionMenuItems}
        </PopperComponent>
      )}
    </Box>
  )
}
