import { lazy, Suspense, useState } from 'react'

import { Button } from '@mui/material'

import IStudyData from 'types/IStudyData'
import IToolData from 'types/IToolData'

const AIDialog = lazy(() => import('components/dialog/AIDialog'))

type AIDialogControlProps = {
  study: IStudyData
  tool: IToolData
}

function AIDialogControl({ study, tool }: AIDialogControlProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="outlined">
        {tool.ai_personality_title ?? 'Generic AI'}
      </Button>

      {open && (
        <Suspense fallback={null}>
          <AIDialog study={study} tool={tool} open={open} onModalClose={() => setOpen(false)} />
        </Suspense>
      )}
    </>
  )
}

export { AIDialogControl }
