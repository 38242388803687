import { lazy, Suspense, useCallback, useEffect, useState, MouseEvent } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AccountCircle as AccountCircleIcon,
  ContentPasteSearch as ContentPasteSearchIcon,
  Help,
  InfoOutlined as InfoOutlinedIcon,
  InsertCommentOutlined as InsertCommentOutlinedIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  Search as SearchIcon,
} from '@mui/icons-material'

import ClipboardDrawer from 'components/sts-app-bar/ClipboardDrawer'
import LanguageSelector from 'components/sts-app-bar/LanguageSelector'

import ClipboardContext, { IClipboard } from 'contexts/ClipboardContext'
import { useDrawerComponentContext } from 'contexts/DrawerComponentContext'
import { useStudyStatesContext } from 'contexts/StudyStatesContext'

import paths from 'utils/paths'

import { colors } from 'shared/theme'
import { Search } from './Search'

const About = lazy(() => import('./About'))

function STSAppBar() {
  const { t } = useLingui()
  const location = useLocation()
  const navigate = useNavigate()

  const [clipboardDrawerOpen, setClipboardDrawerOpen] = useState(false)
  const [aboutOpen, setAboutOpen] = useState(false)
  const [userAnchorEl, setUserAnchorEl] = useState<HTMLElement | null>(null)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const { drawerOpen, setDrawerOpen } = useDrawerComponentContext()
  const { pasteMenuCount } = useStudyStatesContext()

  useEffect(() => {
    const updateTitle = () => {
      let pageTitle = t`STS42`
      if (location.pathname === '/') {
        pageTitle += ` - ${t`Studies`}`
      } else if (location.pathname.includes('/teams/')) {
        pageTitle += ` - ${t`Team`}`
      } else if (location.pathname.startsWith('/study/')) {
        pageTitle += ` - ${t`Study`}`
      } else if (
        location.pathname.startsWith('/account-edit/me') ||
        location.pathname.startsWith('/password-edit/me')
      ) {
        pageTitle += ` - ${t`Profile`}`
      }
      document.title = pageTitle
    }

    updateTitle()
  }, [location.pathname, t])

  const handleOpenAbout = useCallback(() => {
    setUserAnchorEl(null)
    setAboutOpen(true)
  }, [])

  const handleOpenUserMenu = useCallback((e: MouseEvent<HTMLElement>) => {
    setUserAnchorEl(e.currentTarget)
  }, [])

  const handleCloseUserMenu = useCallback(() => {
    setUserAnchorEl(null)
  }, [])

  const handleAccountEditClick = useCallback(() => {
    setUserAnchorEl(null)
    navigate('/account-edit/me')
  }, [navigate])

  const handleLogoutClick = useCallback(() => {
    setUserAnchorEl(null)
    navigate('/auth/logout')
  }, [navigate])

  const isRouteWithSideMenuDrawer = useCallback(() => {
    const { pathname } = location
    return (
      pathname === paths.frontend.home ||
      pathname.includes(paths.frontend.projects) ||
      pathname.includes(paths.frontend.teams()) ||
      pathname.includes(paths.frontend.study())
    )
  }, [location])

  const userMenuItems = [
    {
      label: t`Feedback Form`,
      icon: <InsertCommentOutlinedIcon fontSize="small" />,
      href: 'https://forms.clickup.com/f/dgbxq-7640/OC5XD2091JDD91JT8M',
    },
    {
      label: t`User documentation`,
      icon: <Help fontSize="small" />,
      href: t`https://documentation.sts42.com`,
    },
    {
      label: t`About STS42`,
      icon: <InfoOutlinedIcon fontSize="small" />,
      onClick: handleOpenAbout,
    },
  ]

  return (
    <AppBar position={isRouteWithSideMenuDrawer() ? 'fixed' : 'static'}>
      <Toolbar sx={{ height: '100%', justifyContent: 'space-between' }}>
        <Box
          display="flex"
          component="a"
          href={paths.frontend.home}
          alignItems="center"
          sx={{ textDecoration: 'none' }}
        >
          <Box component="img" sx={{ height: '30px', width: 'auto' }} src="/logo80-white.png" />
          <Box display="flex" margin="0 0 0 8px">
            <Typography variant="h6" color="white">
              STS42
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1, alignItems: 'center' }}>
          {isSearchOpen ? (
            <Search onSearch={() => setIsSearchOpen(false)} />
          ) : (
            <IconButton onClick={() => setIsSearchOpen(true)} sx={{ color: colors.white.A500 }}>
              <SearchIcon />
            </IconButton>
          )}
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)} sx={{ color: colors.white.A500 }}>
            <MenuIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, alignItems: 'center' }}>
          <Search />
          <Tooltip title={t`Open clipboard panel`} disableInteractive>
            <IconButton color="inherit" onClick={() => setClipboardDrawerOpen(true)} size="small">
              <Badge badgeContent={pasteMenuCount} color="warning">
                <ContentPasteSearchIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <LanguageSelector aria-label="language" />
          <a
            href={t`https://documentation.sts42.com`}
            style={{ color: 'inherit', textDecoration: 'none' }}
            target="documentation"
            aria-label="help"
          >
            <Tooltip title={t`Help`} disableInteractive>
              <IconButton color="inherit" size="small">
                <Help fontSize="medium" />
              </IconButton>
            </Tooltip>
          </a>
          <Box>
            <Tooltip title={t`Open user menu`} disableInteractive>
              <IconButton
                aria-controls="user-menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleOpenUserMenu}
                sx={{ ml: 2, px: 0 }}
              >
                <AccountCircleIcon fontSize="large" />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={userAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              id="user-menu-appbar"
              keepMounted
              onClose={handleCloseUserMenu}
              open={Boolean(userAnchorEl)}
              sx={{ mt: '30px' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {localStorage.getItem('user_name') && (
                <MenuItem onClick={handleAccountEditClick} sx={{ py: '4px' }}>
                  <ListItemIcon>
                    <PersonOutlineOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: '0.95rem' }}>
                      {localStorage.getItem('user_name')}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              )}
              <Divider sx={{ my: '8px' }} />
              {userMenuItems.map(({ label, icon, href, onClick }) => (
                <MenuItem
                  key={label}
                  component={href ? 'a' : 'li'}
                  href={href}
                  target="_blank"
                  onClick={onClick || handleCloseUserMenu}
                  sx={{ py: '4px', color: 'inherit', textDecoration: 'none' }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText>
                    <Typography sx={{ fontSize: '0.95rem' }}>{label}</Typography>
                  </ListItemText>
                </MenuItem>
              ))}
              <Divider />
              <MenuItem onClick={handleLogoutClick} sx={{ py: '4px' }}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography sx={{ fontSize: '0.95rem' }}>{t`Logout`}</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>

      <ClipboardContext.Consumer>
        {(clipboard: IClipboard) => (
          <ClipboardDrawer
            onClose={() => setClipboardDrawerOpen(false)}
            open={clipboardDrawerOpen}
            clipboard={clipboard}
          />
        )}
      </ClipboardContext.Consumer>

      {aboutOpen && (
        <Suspense fallback={null}>
          <About open={aboutOpen} onClose={() => setAboutOpen(false)} />
        </Suspense>
      )}
    </AppBar>
  )
}

export default STSAppBar
