import { Dispatch, lazy, SetStateAction, Suspense, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import { Typography, MenuItem, ListItemIcon, Divider, Menu } from '@mui/material'
import {
  DeleteOutline,
  ShareOutlined,
  EditOutlined,
  ContentPasteGo,
  UploadFileOutlined,
  DriveFileMoveOutlined,
} from '@mui/icons-material'

import { useSnackbar } from 'notistack'

import ConfirmDialog from 'components/control/ConfirmDialog'

import StudyListDataService from 'data-services/StudyListDataService'

import { useStudyLists } from 'hooks/useStudyLists'

import paths from 'utils/paths'

import IStudyListData from 'types/IStudyListData'

const ProjectDialog2 = lazy(() => import('components/dialog/ProjectDialog2'))
const FolderMoveDialog = lazy(() => import('components/dialog/FolderMoveDialog'))

type ProjectMoreActionsMenuProps = {
  studyList: IStudyListData
  anchorMenuOpen: boolean
  setProjectMenuAnchorEl: Dispatch<SetStateAction<HTMLElement>>
  projectMenuAnchorEl: HTMLElement
}

function ProjectMoreActionsMenu({
  studyList,
  anchorMenuOpen,
  setProjectMenuAnchorEl,
  projectMenuAnchorEl,
}: ProjectMoreActionsMenuProps) {
  const { t } = useLingui()
  const { id } = useParams()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [projectDialogOpen, setProjectDialogOpen] = useState(false)
  const [projectMoveDialogOpen, setProjectMoveDialogOpen] = useState(false)

  const { mutateStudyLists } = useStudyLists()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleCloseProjectMenu = () => {
    setProjectMenuAnchorEl(null)
  }

  const handleProjectEdit = () => {
    setProjectDialogOpen(true)
    handleCloseProjectMenu()
  }

  const handleProjectMove = () => {
    setProjectMoveDialogOpen(true)
    handleCloseProjectMenu()
  }

  const handleDeleteProjectClick = () => {
    setDeleteDialogOpen(true)
    handleCloseProjectMenu()
  }

  const handleDeleteProjectConfirmClick = () => {
    const project = studyList
    StudyListDataService.deleteStudyList(studyList.id)
      .then(() => {
        mutateStudyLists().then(() => {
          // If we are in the study list's page
          if (id === studyList.id) {
            navigate(paths.frontend.home)
          }

          enqueueSnackbar(
            `Project "${
              project.title && project.title !== '' ? project.title : t`New project`
            }" successfully deleted`,
            { variant: 'success' },
          )
        })
      })
      .catch((err) => {
        enqueueSnackbar(err.data, { variant: 'error' })
      })
  }

  return (
    <>
      <Menu
        id="project-menu"
        MenuListProps={{
          'aria-labelledby': 'project-menu-button',
        }}
        anchorEl={projectMenuAnchorEl}
        open={anchorMenuOpen}
        onClose={handleCloseProjectMenu}
      >
        <MenuItem onClick={handleProjectEdit} sx={{ padding: '8px' }}>
          <ListItemIcon>
            <EditOutlined sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Edit project`}</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => navigate(paths.frontend.teamsSetting(studyList.team.id))}
          sx={{ padding: '8px' }}
        >
          <ListItemIcon>
            <ShareOutlined sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Share`}</Typography>
        </MenuItem>

        <MenuItem onClick={handleProjectMove} sx={{ padding: '8px' }}>
          <ListItemIcon>
            <DriveFileMoveOutlined sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Move`}</Typography>
        </MenuItem>

        <MenuItem onClick={() => {}} sx={{ padding: '8px' }} disabled>
          <ListItemIcon>
            <ContentPasteGo sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Duplicate`}</Typography>
        </MenuItem>

        <MenuItem onClick={() => {}} sx={{ padding: '8px' }} disabled>
          <ListItemIcon>
            <UploadFileOutlined sx={{ color: 'text.primary' }} />
          </ListItemIcon>
          <Typography color="textPrimary">{t`Export`}</Typography>
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={handleDeleteProjectClick}
          className="MuiMenu-error"
          sx={{ padding: '8px' }}
        >
          <ListItemIcon>
            <DeleteOutline color="error" />
          </ListItemIcon>
          <Typography color="error">{t`Delete`}</Typography>
        </MenuItem>
      </Menu>

      <ConfirmDialog
        confirmColor="secondary"
        confirmText={t`Delete project`}
        description={t`Are you sure you want to delete this project?`}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={handleDeleteProjectConfirmClick}
        title={t`Project`}
        confirmButtonVariant="containedRed"
        cancelButtonVariant="containedWhite"
      />

      {projectDialogOpen && (
        <Suspense fallback={null}>
          <ProjectDialog2
            project={studyList}
            open={projectDialogOpen}
            onModalClose={() => setProjectDialogOpen(false)}
          />
        </Suspense>
      )}

      {projectMoveDialogOpen && (
        <Suspense fallback={null}>
          <FolderMoveDialog
            studyList={studyList}
            open={projectMoveDialogOpen}
            onModalClose={() => setProjectMoveDialogOpen(false)}
          />
        </Suspense>
      )}
    </>
  )
}

export { ProjectMoreActionsMenu }
