import { css } from '@emotion/react'
import { SxProps } from '@mui/material/styles'

import theme from 'shared/theme'

const toolColors = theme.palette.tool
const gridItemColors = theme.palette.gridItem

const getToolBorderStyle = (isActive: boolean) => {
  if (isActive) {
    return `2px solid ${toolColors.drag.border}`
  }

  return `1px solid ${toolColors.main.border}`
}

const toolStyle = (isActive = false): SxProps =>
  css({
    minHeight: 0,
    padding: '16px',
    height: '100%',
    boxShadow: 'unset',
    background: toolColors.main.background,
    border: getToolBorderStyle(isActive),
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
  }) as SxProps

const getToolGridItemBorderStyle = (isActive: boolean, hasTool: Boolean) => {
  if (isActive) {
    return `2px solid ${gridItemColors.active.border}`
  }

  return hasTool ? 'unset' : `1px solid ${gridItemColors.main.border}`
}

const getToolWidthPinned = (
  screenWidth: number,
  studyColumnCount: number,
  toolColumnCount: number,
  isPinView = false,
) => {
  if (isPinView) return `calc(${screenWidth / 3}px - 100px)`

  if (studyColumnCount > 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 3}px - 90px)`
  }

  if (studyColumnCount === 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 3}px - 80px)`
  }

  if (studyColumnCount > 0 && toolColumnCount === 0) {
    return `calc(${screenWidth / 3}px - 120px)`
  }

  return `calc(${screenWidth / 1.5}px - 150px)`
}

const getToolWidth = (
  screenWidth: number,
  studyColumnCount: number,
  toolColumnCount: number,
  isMobile = false,
) => {
  if (isMobile) return `calc(${screenWidth}px - 100px)`

  if (studyColumnCount > 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 4}px - 75px)`
  }

  if (studyColumnCount === 0 && toolColumnCount > 0) {
    return `calc(${screenWidth / 2}px - 75px)`
  }

  if (studyColumnCount > 0 && toolColumnCount === 0) {
    return `calc(${screenWidth / 2}px - 140px)`
  }

  return `calc(${screenWidth}px - 125px)`
}

export const toolGridItemStyle = (
  isActive: boolean,
  screenWidth: number,
  toolColumnCount: number,
  studyColumnCount: number,
  hasTool: boolean,
  columnIndex: number,
  isPinnedStationDrawerOpen: boolean,
  viewType: 'grid' | 'pin',
): SxProps =>
  css({
    minWidth: isPinnedStationDrawerOpen
      ? getToolWidthPinned(screenWidth, studyColumnCount, toolColumnCount, viewType === 'pin')
      : getToolWidth(screenWidth, studyColumnCount, toolColumnCount, true),
    [theme.mediaQueryMin.md]: {
      minWidth: isPinnedStationDrawerOpen
        ? getToolWidthPinned(screenWidth, studyColumnCount, toolColumnCount, viewType === 'pin')
        : getToolWidth(screenWidth, studyColumnCount, toolColumnCount),
    },
    height: 'auto',
    margin: columnIndex === 0 ? '24px 0 0 0' : '24px 0 0 32px',
    maxWidth: isPinnedStationDrawerOpen
      ? getToolWidthPinned(screenWidth, studyColumnCount, toolColumnCount, viewType === 'pin')
      : 'unset',

    '& .MuiPaper-tool': {
      minHeight: '100px',
      width: '100%',
      height: '100%',
      boxShadow: 'unset',
      background: 'transparent',
      flex: 1,
      border: getToolGridItemBorderStyle(isActive, hasTool),
    },
  }) as SxProps

export default toolStyle
