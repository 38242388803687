function createNewTool(stationId: string, toolType?: string, row?: number, column?: number) {
  return {
    id: '',
    title: '',
    subtitle: '',
    note: '',
    terms: [],
    station: stationId,
    source_language: '',
    destination_language: '',
    tool_type: toolType ?? '',
    is_template: false,
    is_processing: false,
    ai_provider: '',
    ai_model: '',
    ai_personality: '',
    ai_personality_title: '',
    ai_journal: '',
    is_prompt_to_card: false,
    is_prompt_to_journal: false,
    ai_temperature: 0,
    ai_context_lists: [],
    ai_contexts: undefined,
    is_ai_personality: false,
    language_code: '',
    row: row ?? 0,
    column: column ?? 0,
    prompt: '',
  }
}

export { createNewTool }
