import useSWR, { KeyedMutator } from 'swr'
import { AxiosResponse } from 'axios'
import IStudyListData from 'types/IStudyListData'
import paths from 'utils/paths'

type MutateFunction<Data> = KeyedMutator<Data>

function useRootStudyLists(): {
  rootStudyLists: AxiosResponse<IStudyListData[]> | undefined
  mutateStudyLists: MutateFunction<AxiosResponse<IStudyListData[]>>
} {
  const { data: studyLists, mutate: mutateStudyLists } = useSWR<AxiosResponse<IStudyListData[]>>(
    paths.backend.studyList.rootStudyLists(),
  )

  return { rootStudyLists: studyLists, mutateStudyLists }
}

export { useRootStudyLists }
