import useSWR, { KeyedMutator } from 'swr'
import { AxiosResponse } from 'axios'
import ITeamData from 'types/ITeamData'
import paths from 'utils/paths'

type MutateFunction<Data> = KeyedMutator<Data>

export function useTeams(): {
  teams: AxiosResponse<ITeamData[]> | undefined
  mutateTeams: MutateFunction<AxiosResponse<ITeamData[]>>
} {
  const { data: teams, mutate: mutateTeams } = useSWR<AxiosResponse<ITeamData[]>>(
    paths.backend.study.teams(),
  )

  return { teams, mutateTeams }
}

export function useTeam(id?: string): {
  team: AxiosResponse<ITeamData> | undefined
  isLoading: boolean
  mutateTeam: MutateFunction<AxiosResponse<ITeamData>>
} {
  const {
    data: team,
    isLoading,
    mutate: mutateTeam,
  } = useSWR<AxiosResponse<ITeamData>>(id ? paths.backend.study.teams(id) : null)

  return { team, isLoading, mutateTeam }
}
