import { Dispatch, SetStateAction, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { MutatorCallback } from 'swr'
import { useLingui } from '@lingui/react/macro'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  CardActionArea,
  Avatar,
} from '@mui/material'
import { MoreVert, TableChart } from '@mui/icons-material'

import paths from 'utils/paths'

import IStudyData from 'types/IStudyData'

import { colors } from 'shared/theme'

import StudyMoreActionMenu from 'components/study/StudyMoreActionMenu'

type SortableStudyCardProps = {
  studyListId: string
  study: IStudyData
  mutator: MutatorCallback
  setStudyDialogOpen: Dispatch<SetStateAction<boolean>>
  setSelectedStudy: Dispatch<SetStateAction<IStudyData | null>>
}

const lineHeight = '1.5em'
const numberOfLines = 2
const maxHeight = `calc(${lineHeight} * ${numberOfLines})`

function SortableStudyCard({
  studyListId,
  study,
  mutator,
  setStudyDialogOpen,
  setSelectedStudy,
}: SortableStudyCardProps) {
  const { t } = useLingui()

  const [anchorMenuOpen, setAnchorMenuOpen] = useState(false)
  const anchorMenuRef = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: study.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  }

  return (
    <>
      <Card ref={setNodeRef} style={style} elevation={1} sx={{ height: '100%' }}>
        <CardContent
          sx={{
            padding: '0 !important',
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box
            {...listeners}
            {...attributes}
            sx={{
              width: '10px',
              backgroundColor: colors.blue.A500,
              cursor: 'grab',
              '&:active': {
                cursor: 'grabbing',
              },
            }}
          />
          <CardActionArea
            onClick={() => navigate(paths.frontend.study(study.id))}
            sx={{
              padding: '16px 0 16px 16px',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Avatar sx={{ bgcolor: colors.blue.A500 }}>
                <TableChart />
              </Avatar>

              <div>
                <Typography variant="h5" fontWeight={500} sx={{ color: colors.blue.A800 }}>
                  {study.title && study.title !== '' ? study.title : t`New study`}
                </Typography>
                <Typography
                  whiteSpace="pre-wrap"
                  sx={{
                    lineHeight,
                    maxHeight,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    margin: 0,
                    height: maxHeight,
                  }}
                >
                  {study.note}
                </Typography>
              </div>
            </Box>
          </CardActionArea>

          <Box padding="16px">
            <IconButton
              onClick={() => {
                setAnchorMenuOpen(!anchorMenuOpen)
              }}
              ref={anchorMenuRef}
              size="small"
              sx={{ color: colors.gray.A500, padding: 0 }}
            >
              <MoreVert />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      <StudyMoreActionMenu
        studyListId={studyListId}
        study={study}
        mutator={mutator}
        anchorMenuOpen={anchorMenuOpen}
        setAnchorMenuOpen={setAnchorMenuOpen}
        anchorMenuRef={anchorMenuRef}
        setSelectedStudy={setSelectedStudy}
        setStudyDialogOpen={setStudyDialogOpen}
      />
    </>
  )
}

export { SortableStudyCard }
