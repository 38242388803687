import { lazy, memo, Suspense, useState } from 'react'
import { useLingui } from '@lingui/react/macro'

import { Box, Grid2, CircularProgress, Typography, Container, Button } from '@mui/material'

import { FolderCard } from 'components/project/FolderCard'
import SideMenuDrawer from 'components/SideMenuDrawer'

import { useRootStudyLists } from 'hooks/useRootStudyLists'

import { colors } from 'shared/theme'
import sideMenuDrawerRightBoxStyle from 'shared/components/sideMenuRightBoxStyle'

import IStudyListData from 'types/IStudyListData'

import { AddOutlined } from '@mui/icons-material'

const ProjectDialog2 = lazy(() => import('components/dialog/ProjectDialog2'))

function Projects() {
  const { t } = useLingui()
  const { rootStudyLists } = useRootStudyLists()

  const [folderDialogOpen, setFolderDialogOpen] = useState(false)

  return (
    <>
      <Container
        disableGutters
        maxWidth={false}
        sx={{ padding: { xs: '0 24px 0 24px', md: '0 24px 0 0' } }}
      >
        <Box display="flex">
          <SideMenuDrawer studyLists={rootStudyLists?.data} />

          <Box sx={sideMenuDrawerRightBoxStyle()}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="center"
              margin="0 0 24px 0"
              gap={{ xs: '24px', md: '0' }}
            >
              <Box flexGrow={1}>
                <Typography variant="h1" fontWeight={400} sx={{ color: colors.blue.A800 }}>
                  {t`All projects`}
                </Typography>
              </Box>

              <Box display="flex" gap="24px" justifyContent="flex-end">
                <Button
                  onClick={() => setFolderDialogOpen(true)}
                  variant="containedDarkBlue"
                  sx={{ width: 'auto', padding: '8px 24px' }}
                  startIcon={<AddOutlined fontSize="large" />}
                >
                  <Typography fontWeight={500} noWrap>
                    {t`Add new project`}
                  </Typography>
                </Button>
              </Box>
            </Box>

            {rootStudyLists?.data ? (
              <Grid2 container spacing={2}>
                {rootStudyLists.data.map((studyList: IStudyListData) => (
                  <Grid2 size={{ xs: 12, sm: 6, lg: 4 }} key={`studyList-${studyList.id}`}>
                    <FolderCard studyList={studyList} />
                  </Grid2>
                ))}
              </Grid2>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: '100vh',
                }}
              >
                <CircularProgress sx={{ p: 0.5 }} />
              </Box>
            )}
          </Box>
        </Box>
      </Container>

      {folderDialogOpen && (
        <Suspense fallback={null}>
          <ProjectDialog2 open={folderDialogOpen} onModalClose={() => setFolderDialogOpen(false)} />
        </Suspense>
      )}
    </>
  )
}

export default memo(Projects)
