import { MouseEvent, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLingui } from '@lingui/react/macro'

import {
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
  CardActionArea,
  Avatar,
} from '@mui/material'
import { Folder, MoreVert } from '@mui/icons-material'

import { ProjectMoreActionsMenu } from 'components/project/ProjectMoreActionsMenu'

import paths from 'utils/paths'

import IStudyListData from 'types/IStudyListData'

import { colors } from 'shared/theme'

type FolderCardProps = {
  studyList: IStudyListData
}

const lineHeight = '1.5em'
const numberOfLines = 2
const maxHeight = `calc(${lineHeight} * ${numberOfLines})`

function FolderCard({ studyList }: FolderCardProps) {
  const { t } = useLingui()
  const navigate = useNavigate()

  const [projectMenuAnchorEl, setProjectMenuAnchorEl] = useState<HTMLElement | null>(null)
  const projectMenuOpen = !!projectMenuAnchorEl

  const handleProjectMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setProjectMenuAnchorEl(event.currentTarget)
  }

  return (
    <>
      <Card elevation={1} sx={{ height: '100%' }}>
        <CardContent
          sx={{
            padding: '0 !important',
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <CardActionArea
            onClick={() => navigate(paths.frontend.studyList(studyList.id))}
            sx={{
              padding: '16px 0 16px 16px',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Box sx={{ display: 'flex', gap: '16px' }}>
              <Avatar sx={{ bgcolor: colors.manilla.A500 }}>
                <Folder />
              </Avatar>

              <div>
                <Typography variant="h5" fontWeight={500} sx={{ color: colors.blue.A800 }}>
                  {studyList.title && studyList.title !== '' ? studyList.title : t`New project`}
                </Typography>
                <Typography
                  whiteSpace="pre-wrap"
                  sx={{
                    lineHeight,
                    maxHeight,
                    overflow: 'hidden',
                    display: '-webkit-box',
                    textOverflow: 'ellipsis',
                    margin: 0,
                    height: maxHeight,
                  }}
                >
                  {studyList.note}
                </Typography>
              </div>
            </Box>
          </CardActionArea>

          <Box padding="16px">
            <IconButton
              onClick={handleProjectMenuClick}
              size="small"
              sx={{ color: colors.gray.A500, padding: 0 }}
            >
              <MoreVert />
            </IconButton>
          </Box>
        </CardContent>
      </Card>

      <ProjectMoreActionsMenu
        studyList={studyList}
        anchorMenuOpen={projectMenuOpen}
        setProjectMenuAnchorEl={setProjectMenuAnchorEl}
        projectMenuAnchorEl={projectMenuAnchorEl}
      />
    </>
  )
}

export { FolderCard }
